import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const FLASK_URL = process.env.REACT_APP_PYTHON_API_URL

// add more pdfs
const addPdfsToBot = async (botId, pdfFiles, errorCallback) => {
  const FormData = require('form-data')
  let data = new FormData()

  data.append('pdf_files', pdfFiles)

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${FLASK_URL}/add_pdfs/${botId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  }

  try {
    const response = await axios.request(config)
    // Handle the response if needed
    // successCallback(response.data)
    return response.data
    
  } catch (error) {
    errorCallback(error)
  }

}

// delete uploaded files.
const deleteUploadFile = (botId, index) => {
  const DELETE_UPLOAD_FILE = `${API_URL}/delete-file/${botId}/${index}`
  return axios
    .delete(DELETE_UPLOAD_FILE)
    .then((response) => {
      console.log('File deleted successfully:', response.data)
    })
    .catch((error) => {
      console.error('Error deleting file:', error)
      throw error // Rethrow the error for handling in the component
    })
}

export {addPdfsToBot, deleteUploadFile}
