import axios from 'axios'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URL

export const fetchCustomizedData = async (botId) => {
  try {
    const response = await axios.get(`${API_URL}/customizeBotDatas/${botId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching bot tabs:', error)
    throw error
  }
}

export const createCustomizedData = async (botId, sampleQuestions) => {
  try {
    const response = await axios.post(`${API_URL}/customizeBotDatas/${botId}`, {
      sampleQuestions,
    })
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Suggestion Added Successfully',
        showConfirmButton: false,
        timer: 1500, // Display the alert for 1.5 seconds
      })
    }
    return response.data
  } catch (error) {
    console.error('Error creating bot tab:', error)
    throw error
  }
}

export const deleteCustomizedQuestion = async (botId, questionIndex) => {
  try {
    const response = await axios.delete(`${API_URL}/customizeBotDatas/${botId}/${questionIndex}`)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Question Deleted Successfully',
        showConfirmButton: false,
        timer: 1500, // Display the alert for 1.5 seconds
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed to delete domain',
      })
    }
    return response.data
  } catch (error) {
    console.error('Error deleting question:', error)
    Swal.fire({
      icon: 'error',
      title: 'Failed to delete domain',
    })
    throw error
  }
}
