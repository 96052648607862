import React, {useState} from 'react'
import {StepProps} from '../IAppModels'
import {uploadFile} from '../../../../../utils/embeddingUtils'
import {useAuth} from '../../../../../app/modules/auth'
import {Hourglass} from 'react-loader-spinner'
import Swal from 'sweetalert2'

// import * as fs from 'fs'

// type Step2Data = {
//   botId: string
//   createdDate: string
// }

const Step2 = ({data, updateData}: StepProps) => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const handleFileChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      // Add a check for file size
      const maxSizeInKilobytes = 5000000000000 // 5000000000000 KB
      const fileSizeInKilobytes = file.size / 1024

      if (fileSizeInKilobytes > maxSizeInKilobytes) {
        // Show an error alert for exceeding file size limit
        Swal.fire({
          icon: 'error',
          title: 'File size exceeds the limit',
          html: `Please choose a file smaller than ${maxSizeInKilobytes} KB.<br/> If you want you can <a href="/pricing">Upgrade</a> the plan`,
        })
        return // Exit the function if the file size exceeds the limit
      }

      setLoading(true)

      uploadFile(
        file,
        currentUser,
        (step2Data) => {
          updateData({
            ...data,
            ...step2Data,
          })
          setLoading(false)

          // Show a success alert using SweetAlert2
          Swal.fire({
            icon: 'success',
            title: 'File uploaded successfully!',
            showConfirmButton: false,
            timer: 1500, // Auto close after 1.5 seconds
          })
        },
        (error) => {
          console.error('Error uploading file:', error)
          setLoading(false)

          // Show an error alert using SweetAlert2

          Swal.fire({
            icon: 'error',
            title: 'Error uploading file',
            text: 'There was an error while uploading the file. Please try again.',
          })
        }
      )
    }
  }

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* begin::Form Group */}
        <div className='fv-row'>
          <div>
            <div>
              <input type='file' accept='.pdf' onChange={handleFileChange} required />
              {!data.botId && (
                <div className='fv-plugins-message-container'>
                  <div data-field='userfile' data-validator='notEmpty' className='fv-help-block'>
                    File is required
                  </div>
                </div>
              )}
              <Hourglass
                visible={loading}
                height='25'
                width='25'
                ariaLabel='hourglass-loading'
                wrapperStyle={{}}
                wrapperClass=''
                colors={['#306cce', '#72a1ed']}
              />
            </div>
          </div>
        </div>
        {/* end::Form Group */}
      </div>
    </div>
  )
}

export {Step2}
