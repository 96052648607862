import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Project} from '../pages/Projects/Project'
import {DataSource} from '../pages/Chatbots/DataSource/DataSource'
import {Bot} from '../pages/Chatbots/Bot/Bot'
import {BotLists} from '../pages/Projects/BotLists/BotLists'
import {BotTabs} from '../pages/Chatbots/botTabs/BotTabs'
import { useParams } from 'react-router-dom';
import { Subscription } from '../pages/Subscriptions/subscriptions'
import { Pricing } from '../pages/Subscriptions/pricingChart'
import { TablesWidget6 } from '../../_metronic/partials/widgets'
import { AnalyticsDetails } from '../pages/Analytics/analyticsdetail'
import { ActivityLogs } from '../pages/ActivityLogs/activitylogs'
import { PriceEditPlan } from '../pages/Subscriptions/pricingEditPlan'
import { SubscriptionPaymentPage } from '../pages/Subscriptions/subscriptionPaymentPage'
import {App} from '../pages/IntegratedApp/App'
import { Summarize } from '../pages/IntegratedApp/components/Summerize'
import { Meetingbot } from '../pages/IntegratedApp/components/Meetingbot'





const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const { botId } = useParams();
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='/projects' element={<Project />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />



        <Route path='/connectors/connect' element={<App/>}/>
        <Route path='/connectors/summarize' element={<Summarize/>}/>
        <Route path='/connectors/meetingbot' element={<Meetingbot/>}/>

        <Route
          path='/subscription/subscription-details'
          element={
            //<SuspensedView>
              <Subscription />
            //{/*</SuspensedView>*/}
          }
        />
           <Route
          path='/subscription/pricing-plans'
          element={
            //<SuspensedView>
              <PriceEditPlan />
            //{/*</SuspensedView>*/}
          }
        />
          <Route
          path='/subscription/subscription-pay/:id'
          element={
       
              <SubscriptionPaymentPage  />
          
          }
        />
         <Route path='pricing' element={<Pricing />} />
         <Route path='analytics' element={<AnalyticsDetails/>}/>
         <Route path='avtivitylogs' element={<ActivityLogs/>}/>
        {/* Lazy Modules */}
        <Route
          path='/user-management/users/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
       

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />


        {<Route path='/projects/projects-list' element={<BotLists show={false} handleClose={function (): void {
          throw new Error('Function not implemented.')
        } }/>} />}
        <Route path='/projects/dataSource' element={<DataSource />} />
        <Route path='/projects/chatbot' element={<Bot />} />
        <Route path='/projects/customize-bot/:botId' element={<BotTabs />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
