/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../helpers'
import axios from 'axios'
import {useReactToPrint} from 'react-to-print'
const API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_APP_URL
type Props = {
  className: string
}
interface subsDetails {
  userId: Number,
  tenantId: Number,
  createTime: String,
  subsId: String,
  intent: String,
  payerAddress: String,
  payerEmailid: String,
  payerFname: String,
  payerSname: String,
  payerId: String,
  purchaseAmount: String,
  purchaseCurrency: String,
  purchaseStatus: String,
  updateTime: String,
  isPlanActive: String,
  planId: String,
  plnName: String,
  plnusers: String,
  plnpages: String,
  plntokens: String,
  plnprojects: String,
  invoiceId:String
}
const initialValues = [{
  userId: 0,
  tenantId: 0,
  createTime: "",
  subsId: "",
  intent: "",
  payerAddress: "",
  payerEmailid: "",
  payerFname: "",
  payerSname: "",
  payerId: "",
  purchaseAmount: "",
  purchaseCurrency: "",
  purchaseStatus: "",
  updateTime: "",
  isPlanActive: "",
  planId: "",
  plnName: "",
  plnusers: "",
  plnpages: "",
  plntokens: "",
  plnprojects: "",
  linksDetails: "",
  purchaseUnits: "",
  amounttype: "",
  nextPaymentDate: "",
  invoiceId:""

}]

const TablesWidget9: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(initialValues)
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const fetchPurchaseData = async () => {
    setLoading(true)
    const response = await axios.get(`${API_URL}/subscriptionPurchaseDetails`)
    console.log("Response Data :", response.data)
    setState(response.data)
    setLoading(false)

  }
  useEffect(() => {
    fetchPurchaseData()
  }, [])

  console.log("State:", state)
  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Subscription Details</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{state.length} Subscriptions</span>
        </h3>

      </div>

      <div className='card-body py-3'>

        <div className='table-responsive'>

          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>

            <thead>


              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Subscription ID</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px '>Product Name</th>
                <th className='min-w-100px '>Recurring Amount</th>
                <th className='min-w-100px '>User Name</th>
                <th className='min-w-100px '>Payment Date</th>
                <th className='min-w-100px '>Next Payment Date</th>
                <th className='min-w-100px '>Invoice</th>




              </tr>
            </thead>

            <tbody>
              {state.map((m, idx) => {
                return (
                  <tr>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>

                        <div className='d-flex justify-content-start flex-column'>
                          <div className='text-dark fw-bold text-hover-primary fs-6'>
                            {m.subsId}
                          </div>

                        </div>
                      </div>
                    </td>

                    <td>
                      {m.isPlanActive == "1" ? <span className='badge badge-light-primary fs-7 fw-semibold'>Active</span> : <span className='badge badge-light-primary fs-7 fw-semibold'>Not Active</span>}


                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {m.plnName}
                      </div>

                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {m.purchaseAmount} / {m.amounttype}
                      </div>

                    </td>
                    <td>
                      <a href={`${APP_URL}/user-management/users/pages/profile/overview/` + m.userId} className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {m.payerFname}  {m.payerSname}
                      </a>

                    </td>
                    <td>

                      <span className='badge badge-light-info fs-7 fw-semibold'> {m.createTime}</span>


                    </td>
                    <td>
                      <span className='badge badge-light-info fs-7 fw-semibold'>{m.nextPaymentDate}</span>

                    </td>
                    <td>
                      <div className='d-flex justify-content-start flex-shrink-0'>

                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle="modal"
                          data-bs-target={`#kt_modal_${m.planId}`}
                          //onClick={()=>handledataModal(m.invoiceId,m.subsId,m.createTime,m.plnName,m.purchaseAmount,)}
                          //onClick={()=>preventDe}
                        >
                          View
                        </button>





                      </div>


                    </td>
                    <div className="modal "  id={`kt_modal_${m.planId}`}>
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content ">
                          <div className="modal-header">
                            <h5 className="modal-title">Invoice Details</h5>
                            <div
                              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                              />
                            </div>
                          </div>
                          <div className="modal-body" ref={componentRef} >
                            <div style={{padding:"30px"}}>
                             <div style={{border:"1px solid grey",padding:"20px"}}>
                             <div className='row' style={{margin:"30px 0px",borderRadius:"20px"}}>
                              <div style={{textAlign:'center',fontSize:"30px",fontWeight:"700"}}>Invoice</div>
                              </div>
                            <div className='row' >
                              <div className='col mb6' style={{fontWeight:"700"}}>
                              <div>
                                  Chat Bot Organisation
                                </div>
                                <div>
                                  New Delhi
                                </div>
                                <div>
                                  India
                                </div>
                              </div>
                              <div className='col mb6'style={{textAlign:"right"}}>
                                <div style={{fontWeight:"700"}}>Invoice Details</div>
                                <div>Invoice Id : {m.invoiceId}</div>
                                <div>Subscription Id: {m.subsId}</div>
                                <div>Issue Date : {m.createTime}</div>
                              </div>
                            </div>
                            <div className='row' style={{height:"1px",backgroundColor:"grey",margin:"40px 0px",borderRadius:"20px"}}></div>

                            <div className='row' >
                            <div className="table-responsive">
                              <table className="table table-rounded table-striped table-bordered  border gy-7 gs-7" style={{textAlign:"center"}}>
                                <thead>
                                  <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                    <th className="min-w-100px">PLAN TYPE</th>
                                    <th className="min-w-100px">AMOUNT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{m.plnName}</td>
                                    <td>${m.purchaseAmount}</td>
                                  </tr>
                                 
                                </tbody>
                              </table>
                              </div>
                            </div>
                            <div className='row' style={{padding:"0px" ,fontSize:"15px",fontWeight:"700"}}>
                              <div className='col mb6'></div>
                              <div className='col mb6'style={{textAlign:"center"}}>Total Amount : ${m.purchaseAmount}</div>
                            </div>
                            <div className='row' style={{height:"1px",backgroundColor:"grey",margin:"40px 0px",borderRadius:"20px"}}></div>
                            <div className='row'>
                              <div className='col mb4'>
                                <div style={{fontWeight:"700"}}>BILL TO</div>
                                <div>Address of Payer</div>
                                <div>{m.payerFname } {m.payerSname}</div>
                                <div>{m.payerEmailid}</div>
                                <div>{m.payerAddress}</div>
                              </div>
                              <div className='col mb4'>
                                <div></div>
                              </div>
                              <div className='col mb4' style={{textAlign:"right"}}> 
                                <div style={{fontWeight:"700"}}>PLAN DETAILS</div>
                                <div>Plan Name : {m.plnName}</div>
                                <div>Plan Amount : ${m.purchaseAmount}/ {m.amounttype}</div>
                                <div>Number of tokens : {m.plntokens}</div>
                                <div>Number of Projects : {m.plnprojects}</div>
                                <div>Number of Users : {m.plnusers}</div>

                              </div>
                            </div>
                            <div className='row' style={{margin:"30px 0px",borderRadius:"20px"}}></div>
                           
                              
                              </div> 
                            

                            </div>
                            
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" className="btn btn-success my-1 me-12" onClick={handlePrint}>Print Invoice</button>
                          </div>
                        </div>
                      </div>
                    </div>


                  </tr>

                )
              })}






            </tbody>

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget9 }
