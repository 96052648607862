import React, { useState, useEffect } from "react";
import { getBotDetails, updateBotDetails } from "../../../../utils/botUtils";
import {
  saveSelectedAiModel,
  getSelectedAiModel,
} from "../../../../utils/embeddingUtils";
import Swal from "sweetalert2";
import Switch from "react-switch";
import "./botDetail.scss";

export const BotDetails: React.FC = () => {
  const [botName, setBotName] = useState("");
  const [botId, setBotId] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [updatedBotName, setUpdatedBotName] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [botStatus, setBotStatus] = useState("");
  const [chatWindowVariant, setChatWindowVariant] = useState("");
  const [selectedAiModel, setSelectedAiModel] = useState("");

  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split("/");
    const botID = urlParts[urlParts.length - 1];
    return botID;
  };

  const botID = getBotIDFromURL();

  const fetchBotDetails = () => {
    getBotDetails(botID)
      .then((response) => {
        const data = response;
        setBotName(data.botName);
        setBotId(data.botId);
        setCreatedDate(data.createdDate);
        setUpdatedBotName(data.botName);
        setBotStatus(data.botStatus);
        setIsActive(data.botStatus === "Active");
        setChatWindowVariant(data.chatWindowVariant);
        const initialSelectedAiModel = getSelectedAiModel();
        setSelectedAiModel(initialSelectedAiModel); // Set the AI model here
      })
      .catch((error) => {
        console.error("Error fetching bot details:", error);
      });
  };

  useEffect(() => {
    fetchBotDetails();
  }, [botID]);

  const handleUpdateBotDetails = () => {
    const updatedDetails = {
      botName: updatedBotName,
      botStatus: isActive ? "Active" : "InActive",
      chatWindowVariant,
      selectedAiModel,
    };

    updateBotDetails(botID, updatedDetails)
      .then(() => {
        saveSelectedAiModel(selectedAiModel)
          .then(() => {
            setBotName(updatedBotName);
            setIsSaveButtonDisabled(true);
            Swal.fire({
              icon: "success",
              title: "Data updated successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            console.error("Error saving selected AI model:", error);
          });
      })
      .catch((error) => {
        console.error("Error updating bot details:", error);
      });
  };

  useEffect(() => {
    setIsSaveButtonDisabled(
      updatedBotName === botName &&
        isActive === (botStatus === "Active") &&
        chatWindowVariant === "" &&
        selectedAiModel === getSelectedAiModel() // Check against the current AI model
    );
  }, [
    updatedBotName,
    botName,
    isActive,
    botStatus,
    chatWindowVariant,
    selectedAiModel,
  ]);

  const handleAiModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAiModel = e.target.value;
    setSelectedAiModel(newAiModel);
    setIsSaveButtonDisabled(newAiModel === getSelectedAiModel()); // Update the save button state
    saveSelectedAiModel(newAiModel).catch((error) => {
      console.error("Error saving selected AI model:", error);
    });
  };

  return (
    <div className="card mb-5 mb-xl-5 p-10">
      <div className="row mt-5 mb-6">
        <label className="col-lg-2 col-form-label required fw-bold fs-6">
          Bot Name
        </label>
        <div className="col-lg-4 fv-row">
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            value={updatedBotName}
            placeholder="Bot Name"
            onChange={(e) => {
              setUpdatedBotName(e.target.value);
              setIsSaveButtonDisabled(e.target.value === "");
            }}
          />
        </div>
      </div>
      <div className="row mt-5 mb-6">
        <label className="col-lg-2 col-form-label required fw-bold fs-6">
          Bot Id
        </label>
        <div className="col-lg-4 fv-row">
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            defaultValue={botID}
            placeholder="Bot Id"
            disabled
          />
        </div>
      </div>
      <div className="row mt-5 mb-6">
        <label className="col-lg-2 col-form-label required fw-bold fs-6">
          Created date
        </label>
        <div className="col-lg-4 fv-row">
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            defaultValue={createdDate}
            placeholder="Created Date"
            disabled
          />
        </div>
      </div>
      <div className="row mt-5 mb-6 align-items-sm-center">
        <label className="col-lg-2 col-form-label required fw-bold fs-6">
          Bot Status
        </label>
        <div className="col-lg-4 fv-row">
          <label className="d-flex align-items-sm-center px-5">
            <Switch
              onChange={(checked) => {
                setIsActive(checked);
              }}
              checked={isActive}
              id="botStatusSwitch"
            />
            <span className="ms-4 text-muted">
              {isActive ? "Active" : "InActive"}
            </span>
          </label>
        </div>
      </div>
      <div className="row mt-5 mb-6">
        <label className="col-lg-2 col-form-label required fw-bold fs-6">
          Chat window variant
        </label>
        <div className="col-lg-4 fv-row">
          <select
            className="form-select"
            aria-label="Select Variant"
            value={chatWindowVariant}
            onChange={(e) => setChatWindowVariant(e.target.value)}
          >
            <option value="">Select Variant</option>
            <option value="full-width">Full width</option>
            <option value="right-bottom-corner">Right bottom corner</option>
          </select>
        </div>
      </div>
      <div className="row mt-5 mb-6">
        <label className="col-lg-2 col-form-label required fw-bold fs-6">
          AI Model
        </label>
        <div className="col-lg-4 fv-row">
          <select
            className="form-select"
            aria-label="Select AI Model"
            value={selectedAiModel}
            onChange={handleAiModelChange}
          >
            <option value="">Select AI Model</option>
            <option value="Openai">OpenAI</option>
            <option value="Gemini">Gemini</option>
            {/* <option value='PrivateGPT'>PrivateGPT</option> */}
          </select>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end py-1 px-3">
        <button
          type="submit"
          className="btn btn-primary mt-2"
          onClick={handleUpdateBotDetails}
          disabled={isSaveButtonDisabled}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};
