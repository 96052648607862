import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { KTIcon } from '../../../_metronic/helpers';
import { Pagination } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_APP_URL

const initialData = [{
  userId: 0,
  tenantId: 0,
  role: "",
  createdDate: "",
  details: "",
  type: "",
  _id: ""
}]

const itemsPerPage = 10;



const ActivityLogs: React.FC = () => {
  const [statesdata, setStatesData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0)


  const fetchActivityData = async (page = 1) => {
    setLoading(true);
    const response = await axios.get(`${API_URL}/activitylogs?page=${page}&limit=${itemsPerPage}`);
    console.log('Data:----------->>>>>>>>>>>', response.data.items);
    setStatesData(response.data.items); // Assuming response has an items array
    setTotalPages(response.data.totalPages); // Assuming response has total pages info
    setCount(response.data.totalCount)
    setLoading(false);
  };

  useEffect(() => {
    fetchActivityData(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchActivityData(newPage);
  };

  console.log("Console======>", statesdata);

  const handleDelete =async() =>{
    try {
      const { value: clearlogs } = await Swal.fire({
        title: "Clear Activity Logs",
        input: "number",
        inputLabel: "Enter the number of activity logs you want to Delete",
        inputPlaceholder: `Total Logs: ${count}`,
        inputAttributes: {
          maxlength: "10",
        }
      });
      if (clearlogs) {
        const data = await axios.post(`${API_URL}/deleteactivitylogs`,{logdata:clearlogs}).then(()=>{
          Swal.fire(`You have succesfully deleted ${clearlogs} Activity Logs.`).then(()=>{
            window.location.reload()
          });
        })
      }
    } catch (error) {
      console.log("Error",error);
      
    }
  }

  return (
    <div>
      {/*<TablesWidget13 className='mb-5 mb-xl-8'/>*/}
      <div className={`card 'mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Activity Logs</span>
          </h3>
          <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={handleDelete}
          >
            <KTIcon iconName='trash' className='fs-2' /> 
          </button>
         
        </div>
        </div>
        {/* end::Header */}
        
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>

                  <th className='min-w-150px'>Activity Id</th>
                  <th className='min-w-140px'>UserId</th>
                  <th className='min-w-120px'>Date</th>
                  <th className='min-w-120px'>Details</th>
                  <th className='min-w-120px'>Type</th>
                </tr>
              </thead>

              <tbody>
                {statesdata.map((m, idx) => {
                  return (
                    <tr>

                      <td>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {m._id}
                        </span>
                      </td>
                      <td>

                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{m.userId}</span>
                      </td>
                      <td>

                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{m.createdDate}</span>
                      </td>
                      <td>

                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {m.details}
                        </span>
                      </td>
                      <td ><span className='badge badge-light-success'>{m.type}</span></td>

                    </tr>

                  )
                })}

              </tbody>

            </table>
            {/* end::Table */}
            


          </div>
          {/* end::Table container */}
        </div>
        <div className="pagination-container">
              {/* Render pagination controls here */}
              {/*{[...Array(totalPages).keys()].map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page + 1 === currentPage}
                >
                  {page + 1}
                </button>
              ))}*/}
              <Pagination> 
                <Pagination.Prev /> 
                <Pagination.Ellipsis /> 
                {[...Array(totalPages).keys()].map((page) => (
                  <Pagination.Item onClick={() => handlePageChange(page + 1)}> {page + 1}</Pagination.Item> 
                
              ))}
                <Pagination.Ellipsis /> 
                <Pagination.Next /> 
              </Pagination> 
        </div>






      </div>
    </div>
  )
}

export { ActivityLogs }



