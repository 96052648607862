import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactMarkdown from 'react-markdown'

export const PdfModal = ({isOpen, closeModal, pdfData}) => {
  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Pdf Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display the PDF data using react-markdown */}
        <ReactMarkdown children={pdfData['pdfText'] || ''} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
