import teams from '../teams.png';
//import '../App.css';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../modules/auth';
import '../vscss/Meetingbot.css'
import axios from 'axios';
const PYTHON_API_URL = process.env.REACT_APP_PYTHON_API_URL



const Meetingbot = () => {
    const { currentUser } = useAuth();
    const userId = currentUser?.id;
    const tenantId = currentUser?.tenantId;
    const role = currentUser?.role;
    const emailId = currentUser?.email;
    console.log("Email ID:", emailId);
    console.log(PYTHON_API_URL);


    // State to manage the input values
    const [input1Value, setInput1Value] = useState('');
    const [input2Value, setInput2Value] = useState('');

    // Event handler for input 1 change
    const handleInput1Change = (event) => {
        setInput1Value(event.target.value);
    };

    // Event handler for input 2 change
    const handleInput2Change = (event) => {
        setInput2Value(event.target.value);
    };
    const runmeetingbotapi = async (valueOne, valueTwo)=>{
        const submitdata = await axios.post(`${PYTHON_API_URL}/createMeeetingBot`,{"meetinglink":valueOne,"meetingbotname":valueTwo}).then((res)=>{
            alert("Bot Entered in the Meeting....")
        });
    }

    // Event handler for form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Do something with the input values, for example, send them to a server
        console.log("Input 1 Value:", input1Value);
        console.log("Input 2 Value:", input2Value);
        // Reset the input values after submission if needed
        runmeetingbotapi(input1Value,input2Value)
        
        
    };

    return (
        <div className="">
            <div>
                <h2 className='margintopbottom50px'>Online Meeting Bot</h2>
                <form onSubmit={handleSubmit} className='formofMeetingLink'>
                    <label className='fontSize20px'>
                        Meeting invite link:
                        <input
                            className='margin20px'
                            type="text"
                            value={input1Value}
                            onChange={handleInput1Change}
                            required // Adding required attribute to make the field mandatory
                        />
                    </label>
                    <label className='fontSize20px'>
                        Bot Name:
                        <input
                            className='margin20px'
                            type="text"
                            value={input2Value}
                            onChange={handleInput2Change}
                            required // Adding required attribute to make the field mandatory
                        />
                    </label>
                    <button className='meetingbutton' type="submit">Submit</button>
                </form>
            </div>
        </div >
    );
};

export { Meetingbot };