import React, { useState } from "react";
import { StepProps } from "../IAppModels";
import {
  saveSelectedAiModel,
  getSelectedAiModel,
} from "../../../../../utils/embeddingUtils";

const Step1 = ({ data, updateData, hasError, goToNextStep }) => {
  const [selectedAiModel, setSelectedAiModel] = useState("");

  const handleAiModelChange = (e) => {
    const aiModelValue = e.target.value;
    setSelectedAiModel(aiModelValue);
    // Update data with selected AI model
    updateData({
      ...data,
      selectedAiModel: aiModelValue,
    });
    // Save selected AI model using the API
    saveSelectedAiModel(aiModelValue)
      .then((response) => {
        console.log("Selected AI model saved successfully:", response);
      })
      .catch((error) => {
        console.error("Error saving selected AI model:", error);
      });
  };

  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row mb-10">
          <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
            <span className="required">App Name</span>
            <i
              className="fas fa-exclamation-circle ms-2 fs-7"
              data-bs-toggle="tooltip"
              title="Specify your unique app name"
            ></i>
          </label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="appname"
            placeholder=""
            value={data.appBasic.appName}
            onChange={(e) =>
              updateData({
                appBasic: {
                  appName: e.target.value,
                  appType: data.appBasic.appType,
                },
                selectedAiModel: selectedAiModel, // Include selected AI model
              })
            }
          />
          {!data.appBasic.appName && hasError && (
            <div className="fv-plugins-message-container">
              <div
                data-field="appname"
                data-validator="notEmpty"
                className="fv-help-block"
              >
                App name is required
              </div>
            </div>
          )}
        </div>

        <div className="fv-row mb-10">
          <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
            <span className="required">AI Model</span>
          </label>
          <select
            className="form-select form-select-lg form-select-solid"
            value={selectedAiModel}
            onChange={handleAiModelChange}
          >
            <option value="">Select AI Model</option>
            <option value="Openai">OpenAI</option>
            <option value="Gemini">Gemini</option>
            {/* <option value='PrivateGPT'>PrivateGPT</option> */}
          </select>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
