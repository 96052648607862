import './App.css';
import React from 'react'
import Summarize from './components/Summerize';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';

const baseURL = "https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a/oauth2/v2.0/token";

function App() {

    return (
        <Home />
    );
}

export {App};



