import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword, updatePassword } from '../core/_requests'
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle } from 'react-bootstrap'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_APP_URL

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean>()
  const [stateModal, setStateModal] = useState(false)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [validationError, setValidationError] = useState("");
  const [responsedata, setResponsedata] = useState<any>()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then((result) => {
          if(result){
            setStateModal(true)
          console.log("Result:", result);
          const data = result?.data
          setResponsedata(data)
          }else{
            Swal.fire({
              text: "User Dosn't Exist",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Okay",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = `${APP_URL}/forgot-password`;
              }
            })
          }
          
        })
    },
  })
  const closeModal = () => {
    setStateModal(false)
  }


  const handleNewPassword = async (event) => {
    event.preventDefault();
    console.log(password, confirmPassword);
    if (password !== confirmPassword) {
      setValidationError("Password dosen't Match")
    }
    else {
      const response = await axios.post(`${API_URL}/update-password`, { password: password, userId: responsedata.userId, otpData:otp }).then(() => {

        Swal.fire({
          text: "Password Changed Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = `${APP_URL}`;
          }
        })
      }).catch((err)=>{
        Swal.fire({
          text: "Unable to Authenticate your Account.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = `${APP_URL}`;
          }
        })
      })
    }
    // Submit the form
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      <Modal show={stateModal} onHide={closeModal} centered className='special_modal'>
        <ModalHeader closeButton>
          <ModalTitle>Change Password</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <form noValidate onSubmit={handleNewPassword}>
            <div className="form-group">
              <label htmlFor="passwordForNew">New Password</label>
              <input type="password" className="form-control" id="passwordForNew" placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group" style={{marginTop:"20px"}}>
              <label htmlFor="passwordForNewConfirm">Confirm New Password</label>
              <input type="password" className="form-control" id="passwordForNewConfirm" placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {validationError ? <div className="alert alert-danger">Password dosen't Matched</div> : ""}
            </div>
            <div className="form-group" style={{marginTop:"20px"}}>
              <label htmlFor="otpValue">OTP (Please Check Your Mail Box)</label>
              <input type="text" className="form-control" id="otpValue" placeholder="OTP NUMBER"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                <button type="submit" className="btn btn-primary" style={{margin:"15px",width:"200px",borderRadius:"20px"}} >Submit</button>
              </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}
