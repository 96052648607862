import React from 'react';
//import TableComponent from '../Datacomponent';
import { useEffect, useState } from 'react'
import axios from 'axios';
import '../Tablestyle.css'
import moment from 'moment'
import { useAuth } from '../../../modules/auth';
import { ProgressBar } from 'react-loader-spinner'
const PYTHON_API_URL = process.env.REACT_APP_PYTHON_API_URL


const Summarize = () => {
  //console.log("Data---------------");
  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([]);
  const [respshared, setRespShared] = useState([]);
  const { currentUser } = useAuth();
  const userId = currentUser?.id;
  const tenantId = currentUser?.tenantId;
  const role = currentUser?.role;
  const emailId = currentUser?.email;
  console.log("Email ID:", emailId);
  const [list, setList] = useState()

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`${PYTHON_API_URL}/getvideoData?emaild=${emailId}`);
      const videowithyou = res.data
      setDatas(videowithyou["value"]);
      //const resp = await axios.get(`http://localhost:5050/getvideossharedlist?emaild=${emailId}`)
      //setRespShared(resp.data)
      console.log("Data-----------sdsdsd----", res.data);
      //console.log("Data-----------ssssss----", resp.data);



     
      
      //return JSON.stringify(res.data.value)
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const [datastate, setDataset] = useState()
  const [key, setKey] = useState('tabone');
  const analyseVideo = async (event) => {
    setLoading(true)
    console.log("Buttons Value:", event);
    //console.log("===========>",datastate)
    console.log('Analyze Video', event.meetingurl);
    console.log("Meeting Name:", event.meetingname)
    const res = await axios.post(`${PYTHON_API_URL}/analyzevideo`, { 'video_url': event.meetingurl, 'meeting_name': event.meetingname, 'emailId': emailId }).then((resData) => {
      console.log("Response:::::", resData.data.choices[0].message.content);
      setDataset(resData.data.choices[0].message.content)
    })
    setLoading(false)

  }

  const analyseVideolist = async () => {
    setLoading(true)
    const resapilist = await axios.get(`${PYTHON_API_URL}/summarizevideolist?emailId=${emailId}`)
    setList(resapilist.data)
    console.log("resapilist.data=>", resapilist.data);
    setLoading(false)
  }

  const [ToggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    console.log("Tab Data : ", index);
    setToggleState(index);
    if (index === 3) {
      analyseVideolist()
    }
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";


  return (
    //<div style={{width:"100%"}}>
    //    <TableComponent  dataTwo={respshared ? respshared :""} />
    //</div>
    <div style={{ padding: "30px" }}>
      <h1 style={{ color: "white", textAlign: "center", marginBottom: "50px" }}>Video Summarizer</h1>
      <ProgressBar
        visible={loading}
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <div className="container">
        <ul className="tab-list">
          <li
            className={`tabs ${getActiveClass(1, "active-tabs")}`}
            onClick={() => toggleTab(1)}
          >
            Recordings
          </li>
          {/*<li
            className={`tabs ${getActiveClass(2, "active-tabs")}`}
            onClick={() => toggleTab(2)}
          >
            Shared With You
          </li>*/}
          <li
            className={`tabs ${getActiveClass(2, "active-tabs")}`}
            onClick={() => toggleTab(3)}
          >
            Summaries
          </li>
        </ul>
        <div className="content-container">
          <div className={`content ${getActiveClass(1, "active-content")}`}>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Video Files</th>
                    <th style={{ width: "100px !important" }}>View</th>
                    <th>Category</th>
                    <th>Duration</th>
                    <th>Created</th>
                    <th>Last Modified</th>
                    <th>Summarization</th>
                  </tr>
                </thead>
                {datas ? <tbody>
                  {Object.entries(datas).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{typeof value === 'object' ? JSON.stringify(value.name) : value}</td>
                      <td><a class="anchorclass" href={value.webUrl}>Play</a> </td>
                      <td>{value.media.mediaSource.contentCategory}</td>
                      <td>{moment.utc(value.video.duration).format('HH:mm')}hrs</td>
                      <td>{moment(value.fileSystemInfo.createdDateTime).format("MM/DD/YYYY HH:mm:ss")}</td>
                      <td>{moment(value.fileSystemInfo.lastModifiedDateTime).format("MM/DD/YYYY HH:mm:ss")}</td>
                      <td><button class="button-63" onClick={(e) => { analyseVideo({ meetingurl: value['@microsoft.graph.downloadUrl'], meetingname: value.name }) }}>Summarize</button></td>
                    </tr>
                  ))}
                </tbody> : "No Data Found"}
                
              </table>
            </div>
          </div>
          {/*<div className={`content ${getActiveClass(2, "active-content")}`}>
            <table>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Video Files</th>
                  <th style={{ width: "100px !important" }}>View</th>
                  <th>Duration</th>
                  <th>Created</th>
                  <th>Last Modified</th>
                  <th>Summarization</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(respshared).map(([keys, values]) => (
                  <tr key={keys}>
                    <td>{keys}</td>
                    <td>{typeof values === 'object' ? JSON.stringify(values.name) : values}</td>
                    <td><a class="anchorclass" href={values.webUrl}>Play</a> </td>
                    <td>{moment.utc(values.video.duration).format('HH:mm')}hrs</td>
                    <td>{moment(values.fileSystemInfo.createdDateTime).format("MM/DD/YYYY HH:mm:ss")}</td>
                    <td>{moment(values.fileSystemInfo.lastModifiedDateTime).format("MM/DD/YYYY HH:mm:ss")}</td>
                    <td><button class="button-63" onClick={(e) => { analyseVideo({ meetingurl: values['@microsoft.graph.downloadUrl'], meetingname: values.name }) }}>Summarize</button></td>


                  </tr>
                ))}
              </tbody>
            </table>
          </div>*/}

        </div>
        <div className={`content ${getActiveClass(3, "active-content")}`}>
        {list && list != "undefined" ? <div className='designforcards'> {Object.entries(list).map(([key, value]) => (
          
          <div style={{ marginTop: "100px", padding: "50px", borderStyle: "1px  solid black", borderRadius: "30px" }} >
            <div class="cardsummary">
              <span style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "600" }}>{value.videoName}</span>
              <p>{value.summaryText.choices[0].message.content}</p>
              <button className='button-63' style={{ marginTop: "20px" }}>Share</button>
            </div>
          </div>))}</div>:"No Summarization Found"}         
        </div>
      </div>
    </div>

  );
};

export { Summarize };
