import React, {  useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
const API_URL = process.env.REACT_APP_API_URL

interface Subscription {
    planid: Number,
    planname: String,
    amount: String,
    amounttype: String,
    noofusers: String,
    noofpages: String,
    nooftokens: String,
    noofprojects: String,
    docSize:String,
    tokenSize:String
}

const initialValues = {
    planid: 0,
    planname: "",
    amount: "0",
    amounttype: "month",
    noofusers: "0",
    noofpages: "0",
    nooftokens: "0",
    noofprojects: "0",
    docSize:"",
    tokenSize:""
}

const subscriptionplanSchema = Yup.object().shape({
    planname: Yup.string().required('Plan name is required'),
    amount: Yup.string().required('Amount is required'),
    amounttype: Yup.string().required('Amount Type is required'),
    noofusers: Yup.string().required('Number of Users is required'),
    noofpages: Yup.string().required('Number of Pages is required'),
    nooftokens: Yup.string().required('Number of tokens is required'),
    noofprojects: Yup.string().required('Number of projects is required'),
    docSize: Yup.string().required('Size of document is required'),
    tokenSize: Yup.string().required('Size of token is required')
})

const PriceEditModal: React.FC = () => {
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState<Subscription>(initialValues)

    const [preview, setPreview] = useState('')




    console.log("state:", state)
    const formik = useFormik<Subscription>({
        initialValues: state,
        enableReinitialize: true,
        validationSchema: subscriptionplanSchema,
        onSubmit: async (values) => {
            console.log("Values------------->", values)
            setLoading(true);
            try {
                const response = await axios.post(`${API_URL}/subscriptions/pricingPlan`, {
                    ...values,
                }).then(
                    () => {
                        Swal.fire({
                            text: "New Subscription Plan Added",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Okay",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    }
                )
                console.log(response);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
    })

    console.log("Formilk.values:", formik.values)
    console.log("Initial Values:", initialValues);

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="col-lg-5 mb-5 mb-lg-0"  >
                            <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px" }}>
                                <div className='card-body border-top p-9' style={{ textAlign: "left" }}>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Plan name : </label>

                                        <div className='col-lg-8'>

                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Plan name'
                                                {...formik.getFieldProps('planname')}
                                            //value={state.planname}

                                            />
                                            {formik.touched.planname && formik.errors.planname && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'></div>
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Amount for plan : </label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Enter Amount'
                                                {...formik.getFieldProps('amount')}
                                            />
                                            {formik.touched.amount && formik.errors.amount && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className='row mb-6'>
                                        <div style={{ display: "flex" }}>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>Amount Type :</span>
                                            </label>
                                            <div className='form-check form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input me-3'
                                                    {...formik.getFieldProps('amounttype')}
                                                    name='amounttype'
                                                    type='radio'
                                                    value='month'
                                                    id='kt_modal_update_role_option_0'
                                                    checked={formik.values.amounttype === 'month'}
                                                    disabled={formik.isSubmitting}
                                                />
                                                <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                                                    <div className='fw-bolder text-gray-800'>Per Month</div>
                                                </label>
                                            </div>
                                            <div className='form-check form-check-custom form-check-solid' style={{ paddingLeft: "20px" }}>
                                                <input
                                                    className='form-check-input me-3'
                                                    {...formik.getFieldProps('amounttype')}
                                                    name='amounttype'
                                                    type='radio'
                                                    value='year'
                                                    id='kt_modal_update_role_option_0'
                                                    checked={formik.values.amounttype === 'year'}
                                                    disabled={formik.isSubmitting}
                                                />

                                                <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                                                    <div className='fw-bolder text-gray-800'>Per Year</div>
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                            <span className='required'>Enter the number of Users :</span>
                                        </label>

                                        <div className='col-lg-6 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Number of Users'
                                                {...formik.getFieldProps('noofusers')}

                                            />
                                            {formik.touched.noofusers && formik.errors.noofusers && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{ }</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*{tenantId == 0 &&  */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-5 col-form-label fw-bold fs-6'>
                                            <span className='required'>Size of the Document :</span>
                                        </label>

                                        <div className='col-lg-3 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Number of Pages'
                                                {...formik.getFieldProps('noofpages')}

                                            />
                                            {formik.touched.noofpages && formik.errors.noofpages && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{ }</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-4 fv-row' >
                                            <select
                                                className='form-select form-select-solid form-select-lg'
                                                {...formik.getFieldProps('docSize')}
                                            >
                                                <option value=''>Select ...</option>
                                                <option value='kb'>Kb</option>
                                                <option value='mb'>Mb</option>
                                                <option value='unlimited'>Unlimited</option>
                                            </select>
                                            {formik.touched.docSize && formik.errors.docSize && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'></div>
                                                </div>
                                            )}
                                            
                                        </div>
                                    </div>

                                    {/*}*/}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Number of Tokens :</span>
                                        </label>

                                        <div className='col-lg-4 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Number of Tokens'
                                                {...formik.getFieldProps('nooftokens')}

                                            />
                                            {formik.touched.nooftokens && formik.errors.nooftokens && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'></div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-4 fv-row' >
                                            <select
                                                className='form-select form-select-solid form-select-lg'
                                                {...formik.getFieldProps('tokenSize')}
                                            >
                                                <option value=''>Select ...</option>
                                                <option value='k'>K</option>
                                                <option value='unlimited'>Unlimited</option>
                                            </select>
                                            {formik.touched.tokenSize && formik.errors.tokenSize && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'></div>
                                                </div>
                                            )}
                                            
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Number of Projects :</span>
                                        </label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Number of Projects'
                                                {...formik.getFieldProps('noofprojects')}

                                            />
                                            {formik.touched.noofprojects && formik.errors.noofprojects && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{ }</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>






                                </div>

                                {/*<div className='card-footer d-flex justify-content-end py-6 px-9' style={{ width: "80%", borderRadius: "20px" }}>
                                    <button type='submit' className='btn btn-primary'>
                                
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                
                                    </button>
                                </div>*/}

                                <button type='submit' className="btn btn-primary btn-block p-2 shadow" style={{ width: "80%", borderRadius: "20px" }}>Submit</button>
                            </div>
                        </div>
                        <div className="col-lg-2 mb-5 mb-lg-0" >
                            <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px", width: "300px", color: "grey" }}>
                                <h1 className="h6 text-uppercase font-weight-bold mb-4" style={{ fontSize: "20px", paddingTop: "10px" }}>{formik.values.planname ? formik.values.planname : "Plan Name"} </h1>

                                <h2 className="h1 font-weight-bold" style={{ fontSize: "40px", paddingBottom: "15px", paddingTop: "15px" }}>${formik.values.amount}/<span style={{ fontSize: "20px" }}>{formik.values.amounttype}</span></h2>
                                <div className="custom-separator my-4 mx-auto bg-primary"></div>
                                <ul className="list-unstyled my-5 text-small text-left" style={{ fontSize: "15px" }}>
                                    <li className="mb-3">
                                        <i className="fa fa-check mr-2 text-primary"></i> {formik.values.noofusers} Users</li>
                                    <li className="mb-3">
                                        <i className="fa fa-check mr-2 text-primary"></i> {formik.values.noofpages} Pages</li>
                                    <li className="mb-3">
                                        <i className="fa fa-check mr-2 text-primary"></i> {formik.values.nooftokens}k tokens / month</li>
                                    <li className="mb-3">
                                        <i className="fa fa-check mr-2 text-primary"></i> {formik.values.noofprojects} Projectjs</li>
                                </ul>
                                <button className="btn btn-primary btn-block p-2 shadow rounded-pill" disabled={true} >Subscribe</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


        </div>
    )
}

export { PriceEditModal }