import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
console.log("API URL", API_URL)

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const UPDATE_PASSWORD_URL = `${API_URL}/update-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword (email: string) {
  try{
    const response =await axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
      email,
    })
    console.log("Response:--->",response)
    return response
  }catch(error){
    console.error("Error in requestPassword:", error);
  }

}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function updatePassword(
  userId:number,
  password:string
) {
  return axios.post(UPDATE_PASSWORD_URL, {
    userId,
    password
  })
} 
