import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { OverviewDetails } from '../../../../app/modules/accounts/components/settings/SettingsModel'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth'
const API_URL = process.env.REACT_APP_API_URL

const initialValues ={
  avatar: '',
  firstname: '',
  lastname: '',
  comapanyName: '',
  contactPhone: '',
  companySite: '',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
  role:''
}

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {currentUser} = useAuth()
  console.log("currentUsersssss--->",currentUser?.role);
  const userRole = currentUser?.role
  const avatar = currentUser?.avatar
  const id = currentUser?.id
  const [loading, setLoading] = useState(false)
  const [state,setState] = useState<OverviewDetails>(initialValues)
  const {config} = useLayout()
  const fetchData = async () => {
    setLoading(true)
    const response = await axios.get(`${API_URL}/user/${id}`)
    console.log('Data:-',response.data.data)
    setState({
      avatar: response.data.data.avatar,
      firstname: response.data.data.firstname,
      lastname: response.data.data.lastname,
      comapanyName: response.data.data.comapanyName,
      contactPhone: response.data.data.contactPhone,
      companySite: response.data.data.companySite,
      country: response.data.data.country,
      language: response.data.data.language,
      timeZone: response.data.data.timeZone,
      currency: response.data.data.currency,
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: false,
      role:response.data.data.role
        })
       
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={state.avatar} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
