import React, { useState, useEffect, useRef } from 'react';
import { ChartsWidgetToken } from '../../../_metronic/partials/widgets/charts/ChartsWidget2';
import { StatisticsWidget5, StatisticsWidget6 } from '../../../_metronic/partials/widgets';
import axios from 'axios';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup'
import { useAuth } from '../../modules/auth';
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'

const API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_APP_URL


//Declaring Intial Values of Table Data
const initialValues = {
  selectStartDate: "",
  selectTokenType: ""
}

//Declaring Schema Validation for the Table Data
const tableDataSchema = Yup.object().shape({
  selectStartDate: Yup.string().required("Select Starting Date, from where you want to see details. "),
  selectTokenType: Yup.string().required("Select token usage type.")
})


const AnalyticsDetails: React.FC = () => {

  //Current User Data
  const { currentUser } = useAuth()
  const userId = currentUser?.id
  const tenantId = currentUser?.tenantId
  const role = currentUser?.role
  console.log("Log for Current User Data:(userID,tenantId,role):", userId, tenantId, role);


  //For Card Data

  const [analyticsdata, setAnalyticsdata] = useState<any>()
  const [projects, setProjects] = useState("")
  const [users, setUsers] = useState("")
  const [tokens, setTokens] = useState("")
  const [valuesdatax, setValuesdatax] = useState([])
  const [valuesdatay, setValuesdatay] = useState([])

  const fetchAnalyticsData = async () => {
    const resanalyticsdata = await axios.get(`${API_URL}/analyze`).then((result) => {
      setAnalyticsdata(result.data)
      setProjects(result.data.totalNumOfProjects)
      setUsers(result.data.totalNumberOfUser)
      setTokens(result.data.toatalNumberOfTokensUsed)

    })
  }

  //For Table Data
 
  const fetchDataTable = async () => {
    try {
      const responseOfTableData = await axios.post(`${API_URL}/analyzeTable/${userId}/${tenantId}`)
        .then((result) => {
          console.log("Response for Data Tables", result.data.xaxis)
          setValuesdatax(result.data.xaxis)
          setValuesdatay(result.data.yaxis)

        })
    } catch (err) {
      console.log("Error:", err);
    }



  }

  console.log("valuesDtax----->", valuesdatax)
  console.log("valuesDtay----->", valuesdatay)



  useEffect(() => {

    fetchAnalyticsData()
    fetchDataTable()

   

   

  },[])



  console.log("Analytical Data :", analyticsdata)
 
  return (
    <div>

      <div className='row g-5 g-xl-8'>

        

            <ChartsWidgetToken xaxis={valuesdatax} yaxis={valuesdatay}/>

        

      </div>

      <div className='row g-5 g-xl-8'>


        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='briefcase'
            color='warning'
            iconColor='white'
            title={projects}
            titleColor='white'
            description='Projects'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='cheque'
            color='dark'
            iconColor='white'
            title={users}
            titleColor='white'
            description='Total Members'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='chart-pie-simple'
            color='info'
            iconColor='white'
            title={tokens}
            titleColor='white'
            description='Total Tokens Used'
            descriptionColor='white'
          />
        </div>


        <div className='col-xl-3'>
          {/*<StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='success'
            title='Avarage'
            description='Project Progress'
            progress='50%'
          />*/}
        </div>
      </div>

    </div>
  )
}

export { AnalyticsDetails }



