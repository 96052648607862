import React, {useState, useRef, useEffect} from 'react'
// import {useNavigate} from 'react-router-dom'
// import {Formik, Form, Field, ErrorMessage, useField} from 'formik'
import {useDropzone} from 'react-dropzone'
import './DataSource.scss'
import {PdfModal} from './pdfModal'
import {getBotUploadedData} from '../../../../utils/embeddingUtils'
import {addPdfsToBot, deleteUploadFile} from '../../../../utils/addPdfUtils'
import Swal from 'sweetalert2'
import {Hourglass, TailSpin} from 'react-loader-spinner'
import {KTIcon} from '../../../../_metronic/helpers'

type PdfData = {
  fileName: string
  pdfTexts: string
}

export const DataSource: React.FC = () => {
  const [selectedPdfData, setSelectedPdfData] = useState<PdfData | null>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)
  const [pdfData, setPdfData] = useState<Array<{fileName: string; pdfTexts: string}> | null>(null)
  const [isContentDisabled, setIsContentDisabled] = useState(false)
  const [showComingSoonOverlay, setShowComingSoonOverlay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deletingFileIndex, setDeletingFileIndex] = useState<number | null>(null)

  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split('/')
    const botID = urlParts[urlParts.length - 1] // Get the last part of the URl
    return botID
  }

  const botID = getBotIDFromURL()

  async function fetchData() {
    try {
      const uploadedFileData = await getBotUploadedData(botID)
      setPdfData(uploadedFileData[0]?.pdfInfoArray || [])
      // setFileName(uploadedFileData[0].FileDetails?.pdfText[0]?.fileName)
      // setPdfData(uploadedFileData[0].FileDetails?.pdfText[0]?.pdfTexts
      //   )
      // setFileName(uploadedFileData?.fileName)
      // setPdfData(uploadedFileData?.pdf_texts)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [botID])

  const handleViewData = (pdfData: PdfData) => {
    setSelectedPdfData(pdfData)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedPdfData(null)
    setIsModalOpen(false)
  }

  const handleDeleteFile = (index: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this bot!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        setDeletingFileIndex(index)
        deleteUploadFile(botID, index)
          .then(() => {
            setPdfData((prevPdfData: PdfData[] | null) => {
              if (!prevPdfData) {
                return null
              }

              const updatedPdfData = [...prevPdfData]
              updatedPdfData.splice(index, 1)
              return updatedPdfData
            })

            Swal.fire('File Deleted', 'The File has been deleted successfully!', 'success')
          })
          .catch((error) => {
            console.error('Error deleting file:', error)
            Swal.fire('Error', 'An error occurred while deleting the file', 'error')
          })
          .finally(() => {
            setDeletingFileIndex(null) // Set loading state to false after deletion is complete
          })
      } else {
        setDeletingFileIndex(null) // Set loading state to false if user cancels deletion
      }
    })
  }

  const errorCallback = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'There was an error uploading the PDF. Please try again.',
    })
  }

  const onDrop = async (acceptedFiles) => {
    const pdfFiles = acceptedFiles[0]
    if (pdfFiles) {
      setLoading(true)

      try {
        const response = await addPdfsToBot(botID, pdfFiles, errorCallback)
        console.log('API Response:', response)

        Swal.fire({
          icon: 'success',
          title: 'Pdf Uploaded Successfully',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          fetchData()
        })

        setLoading(false)
      } catch (error) {
        console.error('Error adding PDF to bot:', error)
        setLoading(false)
      }
    }
    //   // Check if any file size is greater than 5MB
    //   const hasOversizedFile = acceptedFiles.some((file) => file.size > 5 * 1024 * 1024); // 5MB in bytes

    //   if (hasOversizedFile) {
    //     helpers.setError('One or more files exceed the maximum allowed size of 5MB');
    //     return;
    //   }

    //   if (acceptedFiles.length + field.value.length > 5) {
    //     helpers.setError('You can only upload a maximum of 5 files at a time');
    //     return;
    //   }

    //   helpers.setValue([...field.value, ...acceptedFiles]);
    // };

    // const clearFiles = () => {
    //   helpers.setValue([]); // Clear the files
    // };
  }

  const {getRootProps, getInputProps, isDragActive, isDragAccept} = useDropzone({
    accept: {
      //'text/html': ['.html'],
      'application/pdf': ['.pdf'],
      // 'application/vdn.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc'],
      // 'application/ms-word': ['.docx', '.doc'],
      // 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx']
    },
    multiple: false,
    onDrop,
  })

  return (
    <div className='card mb-5 mb-xl-5 p-10'>
      <div className='card-body'>
        <div className='text-content'>
          <div className='pdfHead'>
            <h1>PDFs</h1>
            <p>Upload more pdf documents to train your chatbot</p>
          </div>
        </div>
        <div className='contentSection'>
          <div className={`dragPdf ${isContentDisabled ? 'disabled' : ''}`}>
            {showComingSoonOverlay && (
              <div className='comingSoonOverlay'>
                <p>Coming Soon...</p>
              </div>
            )}
            <div {...getRootProps()}>
              <input {...getInputProps()} className='upload-container' />
              {loading ? (
                <div className='uploadingLoader'>
                  <Hourglass
                    visible={loading}
                    height='35'
                    width='35'
                    ariaLabel='hourglass-loading'
                    wrapperStyle={{}}
                    wrapperClass=''
                    colors={['#306cce', '#72a1ed']}
                  />
                  <p className='mt-5 p-drag'>Uploading....</p>
                </div>
              ) : (
                <>
                  <i className='bi bi-upload upload-icon '></i>
                  {isDragActive ? (
                    <p className='p-drag'>Drop the files here ...</p>
                  ) : (
                    <p className='p-drag'>Drag and drop PDF files</p>
                  )}
                </>
              )}
              {/* {meta.error && <p>{meta.error}</p>} */}
            </div>
          </div>
          <div className='readPdf'>
            <h3>FILES</h3>
            <div className='vstack gap-3'>
              {pdfData ? (
                pdfData.map((pdf, index) => (
                  <div key={index} className='d-flex flex-row bd-highlight mb-3'>
                    <div className='p-2 flex-fill bd-highlight'>
                      {pdf.fileName || 'No file name available'}
                    </div>
                    <button
                      className='btn ms-1 btn-active-color-primary btn-sm'
                      onClick={() => handleViewData(pdf || '')}
                    >
                      View Data
                    </button>
                    <button
                      className='btn btn-icon btn-active-color-danger btn-sm ms-1'
                      onClick={() => handleDeleteFile(index)}
                    >
                      {deletingFileIndex === index ? (
                        <TailSpin
                          visible={true}
                          height='25'
                          width='25'
                          color='#fd397a'
                          ariaLabel='tail-spin-loading'
                          wrapperStyle={{}}
                          wrapperClass=''
                        />
                      ) : (
                        <KTIcon iconName='trash' className='fs-3' />
                      )}
                    </button>
                  </div>
                ))
              ) : (
                <p>No PDF data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <PdfModal isOpen={isModalOpen} closeModal={closeModal} pdfData={selectedPdfData} />
      )}
    </div>
  )
}
