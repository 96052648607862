import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './ChatSession.scss'

interface Message {
  content: string
  role: 'user' | 'bot'
  timestamp: string
  _id: string
}

interface ConversationItem {
  message: Message
  _id: string
}

interface Chat {
  _id: string
  bot_created_date: string
  botid: string
  chatid: string
  conversation: ConversationItem[]
  __v: number
}

interface ChatDetailsProps {
  chatId: string
  chats: Chat[]
  onChatSelect: (chatId: string) => void
  onRefresh: () => void
}

const ChatDetails: React.FC<ChatDetailsProps> = ({chatId, chats, onChatSelect, onRefresh}) => {
  console.log(chatId)
  // Find the selected chat based on chatId
  const selectedChat = chats?.find((chat) => chat._id === chatId)
  const chatDate = chats?.map((chat) => {
    return new Date(chat.bot_created_date).toLocaleDateString()
  })

  // Filter out messages without content
  const messagesWithContent: ConversationItem[] | undefined = selectedChat?.conversation.filter(
    (message) => message.message.content
  )
  return (
    <Modal show={!!selectedChat} onHide={() => onChatSelect('')}>
      <Modal.Header closeButton>
        <Modal.Title>Chat Details : {chatDate}</Modal.Title>
        {/* <i className='bi bi-arrow-clockwise refresh-session' onClick={onRefresh}></i> */}
      </Modal.Header>
      <Modal.Body className='chat-modal-body'>
        {messagesWithContent?.map((message) => (
          <div
            key={message._id}
            className={`message ${message.message.role === 'user' ? 'user' : 'bot'}`}
          >
            <p className='message-content'>{message.message.content}</p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => onChatSelect('')}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChatDetails
