import React, {useEffect, useState} from 'react'
import {TablePagination, TableFooter, TableSortLabel} from '@material-ui/core'
import Swal from 'sweetalert2'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../../../app/modules/auth'
import {getBotData, deleteBotData} from '../../../../utils/botUtils'
import {CreateAppModal} from '../create-app-stepper/CreateAppModal'
import {useNavigate} from 'react-router-dom'
import './BotLists.scss'
import axios from 'axios'
const APP_API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_APP_URL
interface BotData {
  botId: string,
  userId:string
  botDetails: {
    botName: string
    createdDate: string
    botStatus: string
  },
  profileDetails:{
    firstname: string,
    comapanyName:string
  }
}

export const BotLists = (show, handleClose, className) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const {currentUser} = useAuth()
  const userId = currentUser?.id
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState('')
  const [filteredBotData, setFilteredBotData] = useState<BotData[]>([])
  const [originalBotData, setOriginalBotData] = useState<BotData[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [orderBy, setOrderBy] = useState('createdDate')
  const [order, setOrder] = useState('desc')
  const role = currentUser?.role

  useEffect(() => {
    fetchBotData()
  }, [])

  useEffect(() => {
    const filteredData = originalBotData.filter((row) =>
      row.botDetails.botName.toLowerCase().includes(searchInput.toLowerCase())
    )

    setFilteredBotData(filteredData)
  }, [searchInput, originalBotData])

  useEffect(() => {
    fetchBotData()
    const updateInterval = 40000
    const intervalId = setInterval(() => {
      fetchBotData()
    }, updateInterval)

    return () => clearInterval(intervalId)
  }, [])

  const fetchBotData = () => {
    getBotData(userId)
      .then((data) => {
        setOriginalBotData(data)
        setFilteredBotData(data)
      })
      .catch((error) => console.error(error))
  }

  const handleCreateNewProject = async () => {
    const response = await axios.get(`${APP_API_URL}/fetchSubsDetails/${userId}`)
    const maxProjectLimit = response.data.plnprojects
    const getBots = await getBotData(userId)
    const getBotLength = getBots.length

    if (maxProjectLimit <= getBotLength) {
      Swal.fire({
        title: 'Limit Exceeded',
        html: 'Max project limit exceeded.<br/> If you want you can <a href="/pricing">Upgrade</a> the plan.',
        icon: 'info',
      })
    } else {
      setShowCreateAppModal(true)
    }
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedBotData = filteredBotData.slice().sort((a, b) => {
    const isAsc = order === 'asc'
    if (orderBy === 'createdDate') {
      return isAsc
        ? a.botDetails.createdDate.localeCompare(b.botDetails.createdDate)
        : b.botDetails.createdDate.localeCompare(a.botDetails.createdDate)
    }
    return 0
  })

  const handleEditProject = (botId: string) => {
    navigate(`/projects/customize-bot/${botId}`)
  }

  const handleDeleteProject = (botId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this bot!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBotData(botId)
          .then(() => {
            fetchBotData()
            Swal.fire('Bot Deleted', 'The bot has been deleted successfully!', 'success')
          })
          .catch((error) => {
            console.error('Error deleting bot:', error)
            Swal.fire('Error', 'An error occurred while deleting the bot', 'error')
          })
      }
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5 flex'>
        <h3 className='card-title align-items-start flex-column mb-6'>
          <span className='card-label fw-bold fs-3 mb-1'>Projects List</span>
          <span className='text-muted fw-semibold fs-7'>Manage all your projects</span>
        </h3>
        <div className='text-center'>
          <button
            onClick={() => handleCreateNewProject()}
            className='btn btn-sm fw-bold btn-primary'
          >
            <KTIcon iconName='plus' className='fs-2' />
            Create Project
          </button>
          <CreateAppModal
            show={showCreateAppModal}
            handleClose={() => setShowCreateAppModal(false)}
          />
        </div>
      </div>
      <div className='card-body py-3'>
        <input
          type='text'
          id='search-box'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder='Search by Project Name'
          className='form-control mb-3'
        />
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-bordered table-row-gray-300'>
            <thead>
              <tr className='fw-bold text-muted bg-gray-200'>
                <th className='ps-4 min-w-125px rounded-start'>
                  <TableSortLabel
                    active={orderBy === 'botDetails.botName'}
                    // direction={order}
                    onClick={() => handleRequestSort('botDetails.botName')}
                  >
                    Project Name
                  </TableSortLabel>
                </th>
                <th className='min-w-125px'>
                  <TableSortLabel
                    active={orderBy === 'createdDate'}
                    // direction={order}
                    onClick={() => handleRequestSort('createdDate')}
                  >
                    Date Created
                  </TableSortLabel>
                </th> 
                {(role == "Admin" || role == "Administrator") && 
                  <th className='ps-4 min-w-125px rounded-start'>
                  <TableSortLabel
                    active={orderBy === 'userId'}
                    // direction={order}
                    onClick={() => handleRequestSort('botDetails.botName')}
                  >
                    Created By
                  </TableSortLabel>
                </th>
                
                }
                {(role == "Admin" || role == "Administrator") && 
                  <th className='ps-4 min-w-125px rounded-start'>
                  <TableSortLabel
                    active={orderBy === 'companyName'}
                    // direction={order}
                    onClick={() => handleRequestSort('ProfileDetails.companyName')}
                  >
                    Company
                  </TableSortLabel>
                </th>
                
                }
                
                <th className='min-w-125px'>Bot Status</th>
                <th className='min-w-200px text-end rounded-end pe-2'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedBotData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => (
                <tr key={row.botId}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column ms-4'>
                        <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {row.botDetails.botName}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-muted fw-semibold text-muted d-block fs-7'>
                      {row.botDetails.createdDate}
                    </p>
                  </td>
                  {(role == "Admin" || role == "Administrator") &&
                  <td>
                  {/*<p className='text-muted fw-semibold text-muted d-block fs-7'>*/}
                    <a href={`${APP_URL}/user-management/users/pages/profile/overview/` + row.userId} className=' fw-bold text-hover-primary d-block fs-6' style={{color:"#00a3ff"}}>
                    {row.profileDetails.firstname?row.profileDetails.firstname:"" }
                    </a>
                    
                  {/*</p>*/}
                </td>
                  }
                  {(role == "Admin" || role == "Administrator") &&
                  <td>
                  <p className='text-muted fw-semibold text-muted d-block fs-7'>
                    {row.profileDetails.comapanyName ? row.profileDetails.comapanyName: "" }
                    
                    
                  </p>
                </td>
                  }
                  
                  <td>
                    <p
                      className={`badge ${
                        row?.botDetails?.botStatus === 'Active' ? 'badge-success' : 'badge-warning'
                      } fs-7 fw-semibold`}
                    >
                      {row.botDetails.botStatus}
                    </p>
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={() => handleEditProject(row.botId)}
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </button>
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => handleDeleteProject(row.botId)}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <TableFooter style={{borderTop: '1px solid border-gray-600'}}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={filteredBotData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </table>
        </div>
      </div>
    </div>
  )
}
