import React from 'react'
// import {useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {CreateAppModal} from './create-app-stepper/CreateAppModal'
import {useAuth} from '../../../app/modules/auth'
import { getBotData } from '../../../utils/botUtils';
import { useNavigate } from 'react-router-dom';

export const Project: React.FC = () => {
  const navigate = useNavigate()
  const {currentUser}= useAuth()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [projects, setProjects] = useState<any[]>([]); // Define the ProjectType interface
  const fetchBotData=()=>{
    const userId= currentUser?.id
    getBotData(userId)
    .then((data) => setProjects(data));
  }
  const handleProjectList = () => {
    // Redirect to the project list page
    navigate('/projects/projects-list')
  };
  useEffect(() => {
    // Fetch initial data
    fetchBotData();
  
    // Poll for updates at a specified interval (e.g., every 30 seconds)
    const updateInterval = 40000; // 30 seconds
  
    const intervalId = setInterval(() => {
      fetchBotData(); // Fetch updated data
    }, updateInterval);
  
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  // function handleCreateProject() {
  //   // Redirect to the "Bot" component
  //   navigate('/projects/dataSource')
  // }
  return (
    <>
    {projects.length > 0 ? (
      navigate('/projects/projects-list')
         ) : (
          <div className=' mt-4 '>
          <div className='row '>
            <div className='col-12'>
              <h1>Projects</h1>
              <div className='d-flex flex-column align-items-center'>
                <div className='mb-3 text-center'>
                  <div className='text-center mt-15 mb-5'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='200'
                      height='200'
                      fill='currentColor'
                      className='bi bi-folder-plus'
                      viewBox='0 0 16 16'
                    >
                      <path d='m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2Zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672Z' />
                      <path d='M13.5 9a.5.5 0 0 1 .5.5V11h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V12h-1.5a.5.5 0 0 1 0-1H13V9.5a.5.5 0 0 1 .5-.5Z' />
                    </svg>
                  </div>
                  <p>Train Chat GPT on your website's data and create a chatbot within minutes or click on project list to view previous chatbot.</p>
                </div>
                <div className='text-center'>
                  <button onClick={() => setShowCreateAppModal(true)} className='btn btn-sm fw-bold btn-primary'>
                    Create Project
                  </button>
                  <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
                </div>
              </div>
            </div>
          </div>
        </div>
         )}
    </>
    
   
  )
}
