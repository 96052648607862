import teams from '../teams.png';
//import '../App.css';
import React from 'react';
import { useAuth } from '../../../modules/auth';
const PYTHON_API_URL = process.env.REACT_APP_PYTHON_API_URL
 


const Home = () => {
    const { currentUser } = useAuth();
    const userId = currentUser?.id;
    const tenantId = currentUser?.tenantId;
    const role = currentUser?.role;
    const emailId = currentUser?.email;
    console.log("Email ID:",emailId);
    console.log(PYTHON_API_URL);
    
    return (
        <div className="">
            <header className="" style={{fontSize:"50px"}}>
                <div style={{ marginTop: "30px" }}>
                <a href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?&client_id=599bb211-c61b-44f1-9c85-2ff2da04d4bf&response_type=code&redirect_uri=${PYTHON_API_URL}/checkapi&response_mode=query&scope=offline_access%20User.Read%20Mail.Read%20TeamsActivity.Read%20ChatMessage.Read%20OnlineMeetingRecording.Read.All%20Files.Read.All%20OnlineMeetings.Read%20OnlineMeetingArtifact.Read.All%20Calendars.Read%20Files.ReadWrite%20Files.ReadWrite.All%20Sites.ReadWrite.All%20Sites.Read.All%20Files.Read&state=${emailId}`}> <img src={teams} className="" alt="logo" style={{ width: "200px" }} /> </a>


               </div>

            </header>


        </div>
    );
  };
  
  export default Home;