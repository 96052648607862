import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import {
  fetchCustomizedData,
  createCustomizedData,
  deleteCustomizedQuestion,
} from '../../../../utils/customizeBot'
import './customizeBot.scss'
import {fetchColors, addColor} from '../../../../utils/widgetColor'
const API_URL = process.env.REACT_APP_API_URL

interface Question {
  question: string
  label: string
}

export const CustomizeBot = () => {
  const [questions, setQuestions] = useState<Question[]>([])
  const [newQuestion, setNewQuestion] = useState<string>('')
  const [newLabel, setNewLabel] = useState<string>('')
  const [headerColor, setHeaderColor] = useState<string>('')
  const [chatIconColor, setChatIconColor] = useState<string>('')
  const [colors, setColors] = useState<string[]>([])

  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split('/')
    const botID = urlParts[urlParts.length - 1] // Get the last part of the URl
    return botID
  }

  const handleHeaderColorChange = (e) => {
    setHeaderColor(e.target.value)
  }

  const handleChatIconColorChange = (e) => {
    setChatIconColor(e.target.value)
  }
  const botID = getBotIDFromURL()

  useEffect(() => {
    // Fetch existing questions and labels when the component mounts
    fetchCustomizedData(botID)
      .then((data) => setQuestions(data[0].sampleQuestions))
      .catch((error) => console.error('Error setting questions:', error))

    // Fetch existing colors when the component mounts
    fetchColors(botID)
      .then((data) => {
        console.log(data)
        setColors(data)
        // Set the input fields with the fetched color data
        if (data.length > 0) {
          setHeaderColor(data[0].headerColor)
          setChatIconColor(data[0].chatIconColor)
        }
      })
      .catch((error) => console.error('Error setting colors:', error))
  }, [])

  const addQuestion = async () => {
    if (newQuestion && newLabel) {
      try {
        // Create or update a bot tab with the new question and label
        await createCustomizedData(botID, [...questions, {question: newQuestion, label: newLabel}])
        // Fetch updated bot tabs after adding a new question
        fetchCustomizedData(botID)
          .then((data) => setQuestions(data[0].sampleQuestions))
          .catch((error) => console.error('Error setting questions after adding:', error))
        setNewQuestion('')
        setNewLabel('')
      } catch (error) {
        console.error('Error adding question:', error)
      }
    }
  }

  const handleDeleteQuestion = async (index) => {
    // Show SweetAlert2 confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms, proceed with the deletion
        deleteQuestion(index)
      }
    })
  }

  const deleteQuestion = async (index: number) => {
    try {
      // Delete the question at the specified index
      await deleteCustomizedQuestion(botID, index)
      // Fetch updated bot tabs after deleting a question
      fetchCustomizedData(botID)
        .then((data) => setQuestions(data[0].sampleQuestions))
        .catch((error) => console.error('Error setting questions after deleting:', error))
    } catch (error) {
      console.error('Error deleting question:', error)
    }
  }
  const handleApplyColors = async () => {
    try {
      // Update or add colors for the bot
      await addColor(botID, {headerColor, chatIconColor})
      // Fetch updated colors after applying
      fetchColors(botID)
        .then((data) => {
          console.log(data)
          setColors(data)
          // Set the input fields with the fetched color data
          if (data.length > 0) {
            setHeaderColor(data[0].headerColor)
            setChatIconColor(data[0].chatIconColor)
          }
        })
        .catch((error) => console.error('Error setting colors after applying:', error))
    } catch (error) {
      console.error('Error applying colors:', error)
    }
  }
  return (
    <div className='p-5 d-flex flex-column'>
      <div className='d-flex flex-row w-75 justify-content-between mb-5 h-50'>
        <div>
          <h1>Add Sample Questions</h1>
          <form>
            <div className='row mt-5 mb-6'>
              <label className='required fw-bold fs-6'>Sample Question:</label>
              <div className='fv-row mt-2'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter sample question'
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                />
              </div>
            </div>
            <div className='row mt-5 mb-6'>
              <label className='required fw-bold fs-6'>Label:</label>
              <div className='fv-row mt-2'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter label for question'
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                />
              </div>
            </div>
            <button className='btn btn-success mt-2' type='button' onClick={addQuestion}>
              Add Question
            </button>
          </form>
        </div>

        <div className='table-container  w-35'>
          <h2 className='mb-2'>Existing Suggestions</h2>
          {questions.length < 1 ? (
            <div>No Suggestion questions found</div>
          ) : (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Question</th>
                    <th scope='col'>Label</th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((q, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                      <th scope='row'>{index + 1}</th>
                      <td>{q.question}</td>
                      <td>{q.label}</td>
                      <td>
                        <button
                          className='removeDomain'
                          onClick={() => handleDeleteQuestion(index)}
                        >
                          <i className='bi bi-trash'></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div className='d-flex'>
        <div className='w-35'>
          <h2>Customize Header and Chat Icon</h2>
          <form>
            <div className='row mt-5 mb-6'>
              <label className='fw-bold fs-6'>Header Color:</label>
              <div className='fv-row mt-2'>
                <input
                  type='text'
                  className='form-control form-control-solid colored-border'
                  placeholder='Enter header color'
                  value={headerColor}
                  onChange={handleHeaderColorChange}
                  style={{borderColor: headerColor}}
                />
              </div>
            </div>
            <div className='row mt-5 mb-6'>
              <label className='fw-bold fs-6'>Chat Icon Color:</label>
              <div className='fv-row mt-2'>
                <input
                  type='text'
                  className='form-control form-control-solid colored-border'
                  placeholder='Enter chat icon color Eg. #007bff'
                  value={chatIconColor}
                  onChange={handleChatIconColorChange}
                  style={{borderColor: chatIconColor}}
                />
              </div>
            </div>
            <button className='btn btn-success mt-2' type='button' onClick={handleApplyColors}>
              Apply Colors
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
