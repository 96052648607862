import React, { useEffect, useState,useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';
import {useLocation, useParams,useNavigate } from "react-router-dom";
import { useAuth } from '../../modules/auth';
import moment from 'moment';
const API_URL = process.env.REACT_APP_API_URL
const APP_URL = process.env.REACT_APP_APP_URL

declare global {
    interface Window {
     paypal: any;
    }
  }

interface Subscription {
    planid: Number,
    planname: String,
    amount: String,
    amounttype: String,
    noofusers: String,
    noofpages: String,
    nooftokens: String,
    noofprojects: String,
}

const initialValues = {
    planid: 0,
    planname: "",
    amount: "",
    amounttype: "",
    noofusers: "",
    noofpages: "",
    nooftokens: "",
    noofprojects: "",
}

const SubscriptionPaymentPage: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { id } = useParams();
    const [state, setState] = useState<Subscription>(initialValues)
    const [loading, setLoading] = useState(false)
    const [paymentConfirm,setPaymentconfirm] = useState(false)
    console.log("Plan ID===>", id)
    const {currentUser} = useAuth()
    console.log("current User Subscription :---->",currentUser?.id);
    const userId = currentUser?.id
    const tenantId = currentUser?.tenantId
    const companyName = currentUser?.companyName
    console.log("current User Company :---->",currentUser?.companyName);
    const fetchData = async () => {
        setLoading(true)
        const response = await axios.get(`${API_URL}/subscription/subscription-pay/${id}`)
        setState({
            planid: response.data.planid,
            planname: response.data.planname,
            amount: response.data.amount,
            amounttype: response.data.amounttype,
            noofusers: response.data.noofusers,
            noofpages: response.data.noofpages,
            nooftokens: response.data.nooftokens,
            noofprojects: response.data.noofprojects
        })
        setLoading(false)
        const amt = Number(response.data.amount)
        const pln = response.data.planname
        const plnId = response.data.planid
        const users = response.data.noofusers
        const pages = response.data.noofpages
        const tokens = response.data.nooftokens
        const projects = response.data.noofprojects
        const amttype = response.data.amounttype

        if(amt){

            window.paypal
            .Buttons({
              createOrder: (data, actions, err) => {
                return actions.order.create({
                  intent: "CAPTURE",
                  purchase_units: [
                    {
                      description: pln,
                      amount: {
                        currency_code: "USD",
                        value: amt,
                      },
                    },
                  ],
                });
              },
              onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                console.log("------------------------------------------->", order);
                const response = axios.post(`${API_URL}/subscriptions/user`,{
                    userId:userId,
                    tenantId:tenantId,
                    createTime:moment( order.create_time).format('MM/DD/YYYY HH:mm:ss'),
                    subsId:order.id,
                    intent:order.intent,
                    linksDetails:order.links[0],
                    payerAddress:order.payer.address.country_code,
                    payerEmailid:order.payer.email_address,
                    payerFname:order.payer.name.given_name,
                    payerSname:order.payer.name.surname,
                    payerId:order.payer.payer_id,
                    purchaseAmount:order.purchase_units[0].amount.value,
                    purchaseCurrency:order.purchase_units[0].amount.currency_code,
                    purchaseUnits:order.purchase_units,
                    purchaseStatus:order.status,
                    updateTime:order.update_time,
                    isPlanActive:1,
                    planId:plnId,
                    plnName:pln,
                    plnusers:users,
                    plnpages:pages,
                    plntokens:tokens,
                    plnprojects:projects,
                    amounttype:amttype,
                    nextPaymentDate: amttype == "month" ? moment( order.create_time).add(1,'months').format('MM/DD/YYYY HH:mm:ss') :  moment( order.create_time).add(1,'years').format('MM/DD/YYYY HH:mm:ss'),
                }).then((response)=>{
                    setPaymentconfirm(true)        
                    Swal.fire({
                        text: "Payment successfully completed"
                                                ,
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Return Home",
                        customClass: {
                            confirmButton: "btn btn-primary"
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {  
                            //<Navigate to='/dashboard' /> 
                            document.location = `${APP_URL}/dashboard`;
                        }else{
                            document.location = `${APP_URL}/dashboard`;
                        }
                    })
                           
                })
              },
              onError: (err) => {
                console.log(err);
              },
            })
            .render(paypal.current);
        }


    }
    const paypal = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        fetchData() 
    }, [])

    console.log("Final Data:", state);
    console.log("sdsdsdsd",paymentConfirm);


    

    return (
        <div>

            {paymentConfirm == true ? <div></div> :<div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center"}} >
                <div>
                    <div className="" style={{ textAlign: "center" }} >
                        <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px", width: "300px", color: "grey" }}>
                            <h1 className="h6 text-uppercase font-weight-bold mb-4" style={{ fontSize: "20px", paddingTop: "10px" }}>{state.planname} </h1>

                            <h2 className="h1 font-weight-bold" style={{ fontSize: "40px", paddingBottom: "15px", paddingTop: "15px" }}>${state.amount}/<span style={{ fontSize: "20px" }}>{state.amounttype}</span></h2>
                            <div className="custom-separator my-4 mx-auto bg-primary"></div>
                            <ul className="list-unstyled my-5 text-small text-left" style={{ fontSize: "15px" }}>
                                <li className="mb-3">
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.noofusers} Users</li>
                                <li className="mb-3">
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.noofpages} Pages</li>
                                <li className="mb-3">
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.nooftokens}k tokens / month</li>
                                <li className="mb-3">
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.noofprojects} Projectjs</li>
                            </ul>
                            {/*<button className="btn btn-primary btn-block p-2 shadow rounded-pill" disabled={true} >Subscribe</button>*/}
                        </div>
                    </div>
                </div>
                <div style={{width:"50%"}}>
                <div ref={paypal}></div>


                </div>
            </div>}
            {/*<div id="paypal-legal-container"></div>*/}
           
                
            
            
        </div>
    )
}

export { SubscriptionPaymentPage }