import React, {useState, useEffect} from 'react'
import ChatList from './ChatList'
import ChatDetails from './ChatDetails'
import containerPng from '../../../../assets/images/conversation.png'
const APP_API_URL = process.env.REACT_APP_API_URL

export const ChatSession: React.FC = () => {
  const [selectedChat, setSelectedChat] = useState<string | null>(null)
  const [chats, setChats] = useState([])
  const [forceRefresh, setForceRefresh] = useState(false)

  const handleChatSelection = (chatId: string | null) => {
    setSelectedChat(chatId)
  }

  const handleRefresh = () => {
    setForceRefresh(true)
  }

  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split('/')
    const botID = urlParts[urlParts.length - 1] // Get the last part of the URl
    return botID
  }

  const botID = getBotIDFromURL()
  const fetchChatData = async () => {
    try {
      const response = await fetch(`${APP_API_URL}/getChatData/${botID}`)
      const data = await response.json()
      console.log(data, '_________________________________')
      setChats(data)
    } catch (error) {
      console.error('Error fetching chat data:', error)
    }
  }

  useEffect(() => {
    fetchChatData()
    // const updateInterval = 40000
    // const intervalId = setInterval(() => {
    //   fetchChatData()
    // }, updateInterval)

    // return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (forceRefresh) {
      fetchChatData()
      setForceRefresh(false)
    }
  }, [forceRefresh])

  return (
    <div className='chatsession-container'>
      {chats?.length === 0 ? (
        <div className='no-chat'>
          <div className='inner-container'>
            <img src={containerPng} style={{width: '150px'}} alt='No Chat History' />
            <div className='no-chat-label'>
              <p>Chat history will appear here.</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <ChatList onChatSelect={handleChatSelection} chats={chats} />
          {selectedChat && (
            <ChatDetails
              chatId={selectedChat}
              chats={chats}
              onChatSelect={handleChatSelection}
              onRefresh={handleRefresh}
            />
          )}
        </>
      )}
    </div>
  )
}
