// botUtils.js

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const SAVE_DATA = `${API_URL}/save-bot`

const saveBotData = (data, currentUser) => {
  const tenantId = currentUser?.tenantId
  const userId = currentUser?.id
  const botId = data?.botId['botId']
  const botStatus = 'Active'
  const chatWindowVariant = 'right-bottom-corner'

  const botData = {
    botName: data.appBasic.appName,
    createdDate: data.createdDate['createdDate'],
    botStatus: botStatus,
    chatWindowVariant: chatWindowVariant,
  }

  return axios
    .post(SAVE_DATA, {
      tenantId,
      userId,
      botId,
      botDetails: botData,
    })
    .then((response) => {
      console.log(response.data.message) // Message from the server
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}

// get all the bots from user.
const getBotData = async (userId) => {
  const GET_DATA = `${API_URL}/get-bots?userId=${userId}`
  try {
    const response = await axios.get(GET_DATA)
    return response.data
  } catch (error) {
    console.error('Error:', error)
    return []
  }
}

// delete bot.
const deleteBotData = (botId) => {
  const DELETE_BOT_ENDPOINT = `${API_URL}/delete-bot/${botId}`
  return axios
    .delete(DELETE_BOT_ENDPOINT)
    .then((response) => {
      console.log('Bot deleted successfully:', response.data)
    })
    .catch((error) => {
      console.error('Error deleting bot:', error)
      throw error // Rethrow the error for handling in the component
    })
}

// // update bot
// const updateBotDetails = (botId, updatedDetails) => {
//   const UPDATE_BOT_DETAILS = `${API_URL}/update-bot/${botId}`

//   return axios
//     .put(UPDATE_BOT_DETAILS, updatedDetails)
//     .then((response) => {
//       console.log(response.data.message) // Message from the server
//     })
//     .catch((error) => {
//       console.error('Error:', error)
//       throw error
//     })
// }

const updateBotDetails = async (botId, newBotDetail) => {
  const UPDATE_BOT_DETAILS = `${API_URL}/update-bot/${botId}`
  if (typeof newBotDetail === 'object') {
    var newBotName = newBotDetail.botName || '' // Extract 'botName' property or use an empty string
  }

  try {
    const response = await axios.put(UPDATE_BOT_DETAILS, {
      botName: newBotName,
      botStatus: newBotDetail.botStatus,
      chatWindowVariant: newBotDetail.chatWindowVariant,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

//get botData from botId

const getBotDetails = async (botId) => {
  const GET_BOT_DETAILS = `${API_URL}/bot-details/${botId}`
  try {
    const response = await axios.get(GET_BOT_DETAILS)
    return response.data
  } catch (error) {
    throw error
  }
}

export {saveBotData, getBotData, deleteBotData, updateBotDetails, getBotDetails}
