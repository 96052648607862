import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css' // Import Bootstrap CSS
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import {Bot} from '../../Chatbots/Bot/Bot'
import {BotDetails} from '../botDetails/botDetails'
import {DataSource} from '../../Chatbots/DataSource/DataSource'
import {ChatSession} from '../../Chatbots/chatsession/ChatSession'
import {CustomizeBot} from '../../Chatbots/customizeBot/customizeBot'
import {AddToWebsite} from '../../Chatbots/addtowebsite/AddtoWebsite'
import {getBotDetails} from '../../../../utils/botUtils' //
import './BotTab.scss'

export function BotTabs() {
  const [key, setKey] = useState<string | null>('botDetails')
  const [botName, setBotName] = useState('')
  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split('/')
    const botID = urlParts[urlParts.length - 1] // Get the last part of the URl
    return botID
  }

  const botID = getBotIDFromURL()
  // Fetch bot details based on botID
  const fetchBotDetails = () => {
    getBotDetails(botID)
      .then((response) => {
        const data = response
        setBotName(data.botName)
      })
      .catch((error) => {
        console.error('Error fetching bot details:', error)
      })
  }

  useEffect(() => {
    // Fetch bot details when the component mounts
    fetchBotDetails()
  }, [botID])
  return (
    <div className='card'>
      <h1 className='mt-2 mb-4 tab-header'>
        Customise your bot :{' '}
        <span className='project-name badge py-3 px-4 fs-7 badge-light-primary'>{`${botName}`}</span>{' '}
      </h1>
      <div className='card-body'>
        <Tabs
          id='centered-tabs'
          activeKey={key as string}
          onSelect={(k) => setKey(k as string | null)}
          className='justify-content-center'
        >
          <Tab eventKey='botDetails' className='stepper-title' title='Bot Details'>
            <BotDetails />
          </Tab>
          <Tab eventKey='dataSources' className='stepper-title' title='Data Sources'>
            <DataSource />
          </Tab>
          <Tab eventKey='chatBot' title='Try ChatBot'>
            <Bot />
          </Tab>
          <Tab eventKey='customizeBot' title='Customize'>
            <CustomizeBot />
          </Tab>
          <Tab eventKey='chatSessions' title='Chat Sessions'>
            <ChatSession />
          </Tab>
          <Tab eventKey='addToSite' title='Add to Site'>
            <AddToWebsite />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}
