import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import './ChatSession.scss'
import Alert from 'react-bootstrap/Alert'
interface Chat {
  _id: string
  botid: string
  chatid: string
  bot_created_date: string
  conversation: {
    role: string
    message: string
    timestamp: string
  }[]
}

interface ChatListProps {
  onChatSelect: (chatId: string) => void
  chats: Chat[] // Pass the dummy chats as props
}

const ChatList: React.FC<ChatListProps> = ({onChatSelect, chats}) => {
  return (
    <div className='vstack gap-3'>
      <div className='border-b border-gray-200 py-4 px-5 session-header'>
        <h3 className='text-xl font-bold leading-6 text-gray-900 '>Chat Logs</h3>
        <p>All the Chat Session will appear here.</p>
      </div>
      <div className='d-flex flex-column'>
        {chats?.length ? ( // Check if chats is present and not empty
          chats.map((chat) => (
            <div
              key={chat._id}
              style={{
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                border: '2px solid #E2E8F0',
                borderRadius: '0.5rem',
              }}
            >
              <div className='date-detail text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                Created Date: {new Date(chat.bot_created_date).toLocaleDateString()}
              </div>
              <div>
                <Button className='view-detail-button' onClick={() => onChatSelect(chat._id)}>
                  <i className='bi bi-eye'></i>
                  View Detail
                </Button>
                {/* <Button
                  className='view-detail-button bg-danger'
                  onClick={() => onChatSelect(chat._id)}
                >
                  <i className='bi bi-trash'></i>
                </Button> */}
              </div>
            </div>
          ))
        ) : (
          <Alert variant='warning'>No chat logs available. Please try again later.</Alert>
        )}
      </div>
    </div>
  )
}

export default ChatList
