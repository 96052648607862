import React, {useRef, useState, useEffect} from 'react'
import './AddtoWebsite.scss'
import Swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_API_URL

export const AddToWebsite: React.FC = () => {
  const [newDomain, setNewDomain] = useState('')
  const [domains, setDomains] = useState<string[]>([])
  const domainInputRef = useRef<HTMLInputElement>(null)

  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split('/')
    const botID = urlParts[urlParts.length - 1] // Get the last part of the URl
    return botID
  }

  const botID = getBotIDFromURL()

  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      `<script id="__aapnaChatbot__" src="${API_URL}/widget.js?botId=${botID}" data-chatbot-id="${botID}"></script>`
    )
    Swal.fire({
      icon: 'success',
      title: 'Copied to clipboard',
      showConfirmButton: false,
      timer: 1500,
    })
  }

  // Fetch existing domains when the component mounts
  const fetchDomains = async () => {
    try {
      const response = await fetch(`${API_URL}/getAllowedDomains?botId=${botID}`)
      if (response.ok) {
        const data = await response.json()
        setDomains(data.allowedDomains)
      } else {
        console.error('Failed to fetch domains')
      }
    } catch (error) {
      console.error('Error fetching domains:', error)
    }
  }

  // Fetch existing domains when the component mounts
  useEffect(() => {
    fetchDomains()
  }, [botID])

  const handleSaveDomain = async () => {
    try {
      // Make a POST request to save the new domain to MongoDB
      const response = await fetch(`${API_URL}/setAllowedDomain`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          botId: botID,
          domains: [newDomain],
        }),
      })

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Domain saved successfully',
        })
        fetchDomains()
        // Clear the input field
        setNewDomain('')
        // if (domainInputRef.current) {
        //   domainInputRef.current.value = ''
        // }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed to save domain',
        })
      }
    } catch (error) {
      console.error('Error saving domain:', error)
    }
  }

  const handleDeleteDomain = (domainToDelete) => {
    // Show SweetAlert2 confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms, proceed with the deletion
        deleteDomain(domainToDelete)
      }
    })
  }

  const deleteDomain = async (domainToDelete: string) => {
    try {
      // Make a DELETE request to remove the domain from MongoDB
      const response = await fetch(`${API_URL}/deleteAllowedDomain`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          botId: botID,
          domain: domainToDelete,
        }),
      })

      if (response.ok) {
        // Update the state to remove the deleted domain
        setDomains((prevDomains) => prevDomains.filter((domain) => domain !== domainToDelete))

        Swal.fire({
          icon: 'success',
          title: 'Domain deleted successfully',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete domain',
        })
      }
    } catch (error) {
      console.error('Error deleting domain:', error)
    }
  }
  return (
    <div className='p-5 d-flex flex-column'>
      <div className='d-flex flex-row w-75 justify-content-between mb-5 h-50'>
        <div className='add-domain-section'>
          <h3 className='required'>Add New Domain</h3>
          <p className='text-font'>Eg. https://www.aapnainfotech.com/</p>
          <div className='d-flex'>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter domain'
              ref={domainInputRef}
              onChange={(e) => setNewDomain(e.target.value)}
            />
          </div>
          <div>
            <button className='btn btn-success mt-2 add-domain' onClick={handleSaveDomain}>
              Add Domains
            </button>
          </div>
        </div>
        <div className='domain-table-section w-25'>
          <h3>Existing Domains</h3>
          {domains.length < 1 ? (
            <div>No Domain found</div>
          ) : (
            <>
              <div className='table-responsive overflow-auto'>
                <table className='table table-bordered table-striped table-hover'>
                  <thead>
                    <tr>
                      <th>Domain</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{height: '50px', overflow: 'auto', width: '30px'}}>
                    {domains.map((domain) => (
                      <tr key={domain}>
                        <td>{domain}</td>
                        <td>
                          <button
                            className='removeDomain'
                            onClick={() => handleDeleteDomain(domain)}
                          >
                            <i className='bi bi-trash'></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='d-flex flex-column overflow-auto h-100 mt-6'>
        <div className='add-header'>
          <div className='add-header-content'>
            <h2 className='chakra-heading css-bdf3ph'>Add to your website</h2>
            <p className='chakra-text css-q9k0mw'>
              Choose any of the below options to add the chatbot to your website.
            </p>
            <div className='script-div card-body'>
              <span style={{color: '#808080'}}>
                &lt;<span style={{color: '#ed6a43'}}>script</span>
                <span style={{color: '#0086b3'}}> id</span>=
                <span style={{color: '#183691'}}>"__aapnaChatbot__"</span>
                <span style={{color: '#0086b3', paddingLeft: '4px'}}> data-chatbot-id</span>=
                <span style={{color: '#183691'}}>"{botID}"</span>
                <br></br>
                <span style={{color: '#0086b3', paddingLeft: '20px'}}> src</span>=
                <span style={{color: '#183691'}}>"{`${API_URL}/widget.js?botId=${botID}`}"</span>
                &gt;
              </span>
              <br></br>
              <span style={{color: '#808080'}}>
                &lt;/<span style={{color: '#ed6a43'}}>script</span>&gt;
              </span>
            </div>
            <div className='button-div'>
              <button
                type='button'
                className='copy-button btn btn-primary'
                onClick={handleCopyClick}
              >
                Copy code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
