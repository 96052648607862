import axios from 'axios'
import Swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_API_URL

export const fetchColors = async (botId) => {
  try {
    const response = await axios.get(`${API_URL}/colors/${botId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching colors:', error)
    throw error
  }
}

export const addColor = async (botId, colorData) => {
  try {
    const response = await axios.post(`${API_URL}/colors/${botId}`, {botId, ...colorData})
    console.log(response.data)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Color Applied Successfully',
        showConfirmButton: false,
        timer: 1500, // Display the alert for 1.5 seconds
      })
    }
    return response.data
  } catch (error) {
    console.error('Error adding color:', error)
    throw error
  }
}
