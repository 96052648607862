import axios from 'axios';

let botId = '';
const API_URL = process.env.REACT_APP_API_URL;
const FLASK_URL = process.env.REACT_APP_PYTHON_API_URL;

let selectedAiModel = '';
const SELECTED_AI_MODEL_KEY = 'selectedAiModel';  // Corrected key to be a string

const getSelectedAiModel = () => {
  return localStorage.getItem(SELECTED_AI_MODEL_KEY) || '';
};

const saveSelectedAiModel = async (newSelectedAiModel) => {
  const SAVE_AI_MODEL_ENDPOINT = `${FLASK_URL}/save_selected_ai_model`;

  try {
    const response = await axios.post(SAVE_AI_MODEL_ENDPOINT, { selectedAiModel: newSelectedAiModel });
    // Update the selectedAiModel variable with the new value
    selectedAiModel = newSelectedAiModel;
    localStorage.setItem(SELECTED_AI_MODEL_KEY, newSelectedAiModel); // Save the selected AI model in localStorage
    return response.data;
  } catch (error) {
    console.error('Error saving selected AI model:', error);
    throw error;
  }
};

const uploadFile = (file, currentUser, successCallback, errorCallback) => {
  const FormData = require('form-data');
  let data = new FormData();
  const userId = currentUser['id'];
  data.append('pdf_files', file);
  data.append('currentUser', userId);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${FLASK_URL}/process_pdfs`,
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      botId = response.data[1];
      const createdDate = response.data[2];

      const step2Data = {
        botId: botId,
        createdDate: createdDate,
      };

      successCallback(step2Data);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const getBotUploadedData = async (botId) => {
  const SAVE_AND_FETCH_DATA = `${API_URL}/saveAndFetchData/${botId}`;

  try {
    const response = await axios.get(SAVE_AND_FETCH_DATA);
    const dataFromMongoDB = response.data;
    return dataFromMongoDB;
  } catch (error) {
    console.error('Error fetching and saving data:', error);
  }
};
// const getBotUploadedData = async (botId) => {
//   const GET_UPLOADED_DATA = `${baseURL}/get_bot_uploaded_data/${botId}`
//   try {
//     const response = await axios.get(GET_UPLOADED_DATA)
//     console.log(response)
//     return response.data
//   } catch {}
// }

// const fetchDataFromPythonAndSaveToMongo = async (botId) => {
//   const SAVE_AND_FETCH_DATA = `http://localhost:5050/api/saveAndFetchData/${botId}`;

//   try {
//     const response = await axios.get(SAVE_AND_FETCH_DATA);
//     const dataFromMongoDB = response.data;

//     console.log('Data fetched from MongoDB:', dataFromMongoDB);
//     // Now you can use this data in your React component
//   } catch (error) {
//     console.error('Error fetching and saving data:', error);
//   }
// };
export {uploadFile, botId, getBotUploadedData,saveSelectedAiModel,getSelectedAiModel}
