import React, {useEffect, useState} from 'react'
import './Bot.scss'
import {botId} from '../../../../utils/embeddingUtils'
import axios from 'axios'
import {useAuth} from '../../../modules/auth'
const APP_API_URL = process.env.REACT_APP_API_URL
const API_URL = process.env.REACT_APP_PYTHON_API_URL

export const Bot: React.FC = () => {
  const [isMinimized, setIsMinimized] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [chatMessages, setChatMessages] = useState<string[]>([])
  const {currentUser} = useAuth()
  const userId = currentUser?.id
  const tenantId = currentUser?.tenantId

  const getBotIDFromURL = () => {
    const urlParts = window.location.href.split('/')
    const botID = urlParts[urlParts.length - 1] // Get the last part of the URl
    return botID
  }

  const botID = getBotIDFromURL()

  const toggleChatbox = () => {
    setIsMinimized(!isMinimized)
  }

  const saveBotTokens = async () => {
    try {
      const response = await axios.post(`${APP_API_URL}/save_token_count/${userId}/${botID}`, {
        tenantId,
      })
      const data = await response.data
    } catch (error) {
      console.error('Error fetching chat data:', error)
    }
  }

  useEffect(() => {
    saveBotTokens()
  }, [botID])

  // const fetchData = async () => {
  //   const response = await axios.get(`http://localhost:3001/fetchSubsDetails/${userId}`)
  //   console.log(response.data, '^^^^^^^^^^^^^^^^^^')
  //   return response.data
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [])

  const saveChatData = async () => {
    try {
      const response = await fetch(`${APP_API_URL}/saveChatData/${botId}`)
      const data = await response.json()
    } catch (error) {
      console.error('Error fetching chat data:', error)
    }
  }

  const addUserMessage = (message: string) => {
    setChatMessages([...chatMessages, `User: ${message}`])
  }

  const addBotMessage = (message: string) => {
    setChatMessages([...chatMessages, `Bot: ${message}`])
  }

  const respondToUser = async (userMessage: string) => {
    try {
      const response = await fetch(`${API_URL}/ask_question/${botID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user_question: userMessage}),
      })

      if (!response.ok) {
        throw new Error('Chatbot request failed')
      }

      const data = await response.json()
      saveBotTokens()
      saveChatData()
      const subscriptionData = await axios.get(`${APP_API_URL}/fetchSubsDetails/${userId}`)
      const maxTokenLimit = +subscriptionData.data.plntokens
      const totalTokenUsed = await axios.get(`${APP_API_URL}/get_token_count/${userId}/${botID}`)
      if (maxTokenLimit < totalTokenUsed.data.totalTokens) {
        addBotMessage('token limit exceed.')
        return
      }
      const botMessages = data.messages || []
      for (const message of botMessages) {
        addBotMessage(message.content)
      }
    } catch (error) {
      console.error('Error communicating with the chatbot:', error)
    }
  }

  const handleSendButtonClick = () => {
    if (userInput.trim() !== '') {
      addUserMessage(userInput)
      respondToUser(userInput)
      setUserInput('')
    }
  }

  const handleUserInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendButtonClick()
    }
  }

  return (
    <div className='d-flex flex-column overflow-auto'>
      <div className='try-bot-header'>
        <div className='try-bot-inner'>
          <h2 className='chakra-heading css-bdf3ph'>Try out chatbot</h2>
          <p className='chakra-text css-q9k0mw'>
            Chat with your chatbot and see how it responds. If you don't get the desired response,
            follow the instructions below.
          </p>
        </div>
      </div>
      <div className='d-flex flex-row '>
        <div className='d-flex flex-column w-50 p-7 '>
          <div
            className='alert alert-primary d-flex flex-row align-items-center rounded'
            role='alert'
          >
            <span>
              <i className='bi bi-exclamation-diamond me-3'></i>
            </span>

            <div className='alert-msg'>
              <h3>Not getting proper response</h3>
              <p className='text-primary'>
                It is all about the training data. Here are some ways to improve the accuracy of
                your chatbot.
              </p>
            </div>
          </div>
          <div className='tips-1'>
            <div className='tip-number'> 1</div>
            <h2 className='chakra-heading EditChatbot_resultTipHeading__L7BYQ css-1owbjjc'>
              Ensure Correct Crawling of data from pdf.
            </h2>
            <p>
              The more data you add, the better the chatbot response will be. You can add custom
              data to train in the form of question-answer pairs from the provided data.
            </p>
          </div>
          <div className='tips-1'>
            <div className='tip-number'>2</div>
            <h2>Train Chatbot with Previous Support Questions.</h2>
            <p>
              To reduce the number of repetitive emails and inquiries, make sure to train your
              chatbot with past support questions and answers.
              <span className='chakra-text css-1lglquy'>Train custom data</span> section{' '}
            </p>
          </div>
          <div className='tips-1'>
            <div className='tip-number'> 3</div>
            <h2>Contact Us</h2>
            <p>
              Still not statisfied? Email us at
              <a href='mailto:info@aapnainfotech.com'> AapnaInfotech</a>. We'll help you with the
              setup.
            </p>
          </div>
          {/* <div className='tips-1'>
          <div className='tip-number'> 4</div>
          <h2>
          </h2>
          <p>
          </p>
        </div> */}
        </div>
        <div className='w-50 align-items-end'>
          <div id='chat-container' className={` ${isMinimized ? 'minimized' : ''}`}>
            <div className='shadow rounded-lg max-w-lg w-full inner-chat-container'>
              <div className='p-4 border-bottom bg-primary text-white rounded-top d-flex justify-content-between align-items-center'>
                <p className='text-lg font-weight-bold'>I am your AI assistant. Ask me anything</p>
                <button id='close-chat' className='btn btn-link text-light' onClick={toggleChatbox}>
                  <i className='bi bi-x'></i>
                </button>
              </div>
              <div id='chatbox' className='p-4 chatbox-height-40 overflow-auto card-body'>
                {chatMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-2 d-flex ${
                      message.startsWith('User:') ? 'justify-content-end' : 'justify-content-start'
                    }`}
                  >
                    <p
                      className={`rounded py-2 px-4 inline-block w-60 ${
                        message.startsWith('User:') ? 'bg-primary text-white' : 'bg-light text-dark'
                      }`}
                    >
                      {message}
                    </p>
                  </div>
                ))}
              </div>
              <div className='p-4 border-top d-flex'>
                <input
                  id='user-input'
                  type='text'
                  placeholder='Type a message'
                  className='form-control rounded-start'
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyPress={handleUserInputKeyPress}
                />
                <button
                  id='send-button'
                  className='btn btn-primary rounded-end'
                  onClick={handleSendButtonClick}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
