import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../modules/auth';
const API_URL = process.env.REACT_APP_API_URL

interface SuccessPage {
    planid: Number,
    planName: String,
    noofusers: String,
    noofpages: String,
    nooftokens: String,
    noofprojects: String,
    isplanactive: Number
}

const initialValues = {
    planid: 0,
    planName: "",
    noofusers: "",
    noofpages: "",
    noofprojects: "",
    nooftokens: "",
    isplanactive: 0

}

const SuccessPaymentDisplay: React.FC = () => {
    const { currentUser } = useAuth();
    const userId = currentUser?.id;
    const tenantId = currentUser?.tenantId;
    const role = currentUser?.role;
    const [state, setState] = useState<SuccessPage>(initialValues)

    const fetchData = async () => {
        const response = await axios.get(`${API_URL}/fetchSubsDetails/${userId}`);
        setState({
            planid: response.data.planId,
            planName: response.data.plnName,
            noofusers: response.data.plnusers,
            noofpages: response.data.plnpages,
            noofprojects: response.data.plnprojects,
            nooftokens: response.data.plntokens,
            isplanactive: response.data.isPlanActive
        })

    }
    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div>
            <div className='row'>
                <div className='col mb6'>
                    <div className={`card bg-dark hoverable `} style={{  }}>
                        <div className="card-header ribbon ribbon-end">
                            <div className="ribbon-label bg-primary">Plan Detail</div>
                        </div>
                        <div className='card-body' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className={`text-white fw-bold fs-4 mb-2 mt-5`} >{state.planName ? state.planName : "No active plan. Please Upgrade your plan."}</div>
                            <a href="/pricing" className="btn btn-primary" style={{ borderRadius: "30px", backgroundColor: "white", color: "#071437" }}>Upgrade</a>
                        </div>

                    </div>
                </div>
                <div className='col mb6'></div>
            </div>
            {/*<div className="" style={{ textAlign: "center" }} >



                <div className="bg-white p-5 rounded-lg  row shadow" style={{ borderRadius: "20px", width: "100%", color: "grey", padding: "20px" }}>
                    <div className='col mb-4' style={{ fontSize: "40px", alignItems: "center", display: "flex", justifyContent: "center", clipPath: "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)", backgroundColor: "#58bb0ed1", color: "white", borderRadius: "20px" }}>
                        <div className="" style={{ fontSize: "40" }}>Active Plan</div>
                    </div>

                    <div className='col mb-8' style={{ alignItems: "center", clipPath: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)", color: "Black", backgroundColor: "rgb(60 113 21)", padding: "20px" }}>

                        <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px", width: "100%", color: "grey", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", padding: "20px", backgroundColor: "#f5f5f5" }}>
                            <h1 className="h6 text-uppercase font-weight-bold mb-4" style={{ fontSize: "20px", paddingTop: "10px", color: "#333" }}>{state.planName} </h1>
                            <div className="custom-separator my-4 mx-auto bg-primary" style={{ height: "2px", width: "50px", borderRadius: "50%" }}></div>
                            <ul className="list-unstyled my-5 text-small text-left" style={{ fontSize: "15px" }}>
                                <li className="mb-3" style={{ color: "#333" }}>
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.noofusers} Users</li>
                                <li className="mb-3" style={{ color: "#333" }}>
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.noofpages} Pages</li>
                                <li className="mb-3" style={{ color: "#333" }}>
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.nooftokens}k tokens / month</li>
                                <li className="mb-3" style={{ color: "#333" }}>
                                    <i className="fa fa-check mr-2 text-primary"></i> {state.noofprojects} Projects</li>
                            </ul>
                        </div>
                    </div>



                </div>
            </div>*/}
        </div>
    )
}

export { SuccessPaymentDisplay }