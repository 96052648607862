/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import axios from 'axios'
import { SuccessPaymentDisplay } from '../Subscriptions/successPayDisplay'
import { useAuth } from '../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL



export const REGISTER_URL = `${API_URL}/fileUpload`
export function fileUpload(
  file: "",
) {
  return axios.post(REGISTER_URL, {
    file
  })
}

interface SuccessPageDash {
  isplanactive: Number
}

const initialValues = {
  isplanactive: 0

}

const DashboardPage: FC = () => {
  const { currentUser } = useAuth();
  const userId = currentUser?.id;
  const tenantId = currentUser?.tenantId;
  const role = currentUser?.role;
  const [state, setState] = useState<SuccessPageDash>(initialValues)

  const fetchData = async () => {
    const response = await axios.get(`${API_URL}/fetchSubsDetails/${userId}`);
    setState({
      isplanactive: response.data.isPlanActive
    })

  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {state.isplanactive == 1 ?
          <div className='col-xxl-12'>
            <SuccessPaymentDisplay />
          </div> : 
          <div className='col-xxl-12'>
            <EngageWidget10 className='h-md-100' />
          </div>}
      </div>


    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
