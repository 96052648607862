/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC,useState,useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie"
import { TablesWidget9 } from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL


const Subscription: FC = () => (
  <div>
     <TablesWidget9 />
  </div>
)


export {Subscription}
