import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL


interface Subscription {
  planid: Number,
  planname: String,
  amount: String,
  amounttype: String,
  noofusers: String,
  noofpages: String,
  nooftokens: String,
  noofprojects: String,
}

const initialValues = [{
  planid: 0,
  planname: "",
  amount: "0",
  amounttype: "",
  noofusers: "",
  noofpages: "",
  nooftokens: "",
  noofprojects: "",
}]

const Pricing: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(initialValues)
  const [checkout, setCheckout] = useState(false)
  const navigate = useNavigate();




  const fetchData = async () => {
    setLoading(true)
    const response = await axios.get(`${API_URL}/subscriptions`)
    console.log('Data:-', response.data)
    setState(response.data)
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [])

  const checkOutData = (data) => {
    console.log("QQQQ===>", data)
    navigate(`/subscription/subscription-pay/${data.planid}`);
    //?am=${data.amount}&&pl=${data.planname}

  }


  return (<div>
    <section>
      <div>
        <h2 style={{ textAlign: "center", paddingBottom: "10px", fontSize: "25px", color: "#2e5d1b" }}>Choose Your Plan</h2>
      </div>
      <div className="py-5" style={{ display: "flex", justifyContent: "center" }}>



        <div className="row text-center align-items-end" style={{ width: "80%" }}>


          {state.map((m, idx) => {
            return (
              <div className="col-lg-4" style={{ paddingTop: "30px" }}>
                <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px", width: "300px" }}>
                  <h1 className="h6 text-uppercase font-weight-bold mb-4" style={{ fontSize: "20px", paddingTop: "10px" }}>{m.planname} </h1>

                  <h2 className="h1 font-weight-bold" style={{ fontSize: "40px", paddingBottom: "15px", paddingTop: "15px" }}>${m.amount}/<span style={{ fontSize: "20px" }}>{m.amounttype}</span></h2>
                  <div className="custom-separator my-4 mx-auto bg-primary"></div>
                  <ul className="list-unstyled my-5 text-small text-left" style={{ fontSize: "15px" }}>
                    <li className="mb-3">
                      <i className="fa fa-check mr-2 text-primary"></i> {m.noofusers} Users</li>
                    <li className="mb-3">
                      <i className="fa fa-check mr-2 text-primary"></i> {m.noofpages} Pages</li>
                    <li className="mb-3">
                      <i className="fa fa-check mr-2 text-primary"></i> {m.nooftokens} tokens / month</li>
                    <li className="mb-3">
                      <i className="fa fa-check mr-2 text-primary"></i> {m.noofprojects} Projects</li>
                  </ul>
                  <button  className="btn btn-primary btn-block p-2 shadow rounded-pill" onClick={()=>{checkOutData(m)}}>Subscribe</button>    
                  

                </div>
              </div>
            )
          })}


        </div>
      </div>

    </section>
    <div style={{ height: "80%", width: "80%", backgroundColor: "#b5f56f", position: "absolute", zIndex: "-1", top: "7%", clipPath: "circle(50% at 50% 0%)", boxShadow: " -8px 33px 41px 9px rgba(0,0,0,0.1)" }}></div>
  </div>)
}

export { Pricing }