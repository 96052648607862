/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import axios from 'axios'
import  {useAuth} from '../../../../app/modules/auth'
const API_URL = process.env.REACT_APP_API_URL


interface ChartsWidget2Props   {
  xaxis:any[],
  yaxis:any[]
}

const ChartsWidgetToken: React.FC<ChartsWidget2Props > = ({xaxis,yaxis}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const userId = currentUser?.id
  const tenantId = currentUser?.tenantId
  console.log("Dta------------------>",xaxis,yaxis);
    //For Table Data
    const [valuesdatax, setValuesdatax] = useState([])
    const [valuesdatay, setValuesdatay] = useState([])
    const [element, setElement] = useState<Promise<Element> | null>(null);
    const fetchDataTable = async () => {
      try {
        const responseOfTableData = await axios.post(`${API_URL}/analyzeTable/${userId}/${tenantId}`)
          .then((result) => {
            console.log("Response for Data Tables", result.data.xaxis)
            setValuesdatax(result.data.xaxis)
            setValuesdatay(result.data.yaxis)
  
          })
      } catch (err) {
        console.log("Error:", err);
      }
  
  
    }
  
    console.log("valuesDta----->", valuesdatax)
    console.log("valuesDta----->", valuesdatay)
  
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height,xaxis,yaxis))
    if (chart) {
      chart.render()
    }

    return chart
  }
  const handlerefresh = () => {
    refreshChart()
  }


  useEffect(() => {
    fetchDataTable()
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card card-xl-stretch mb-xl-8`} >
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
      
          
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Tokens Usage</span>

          <span className='text-muted fw-semibold fs-7'>16 Members</span>
         
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
        <button onClick={handlerefresh} style={{padding:"6px 10px",borderRadius:"50%",backgroundColor:"#d7d7dd",boxShadow: "-1px -1px 15px 0px rgba(0,0,0,0.75)"}}><i className="bi bi-arrow-clockwise"></i>
</button>
        </div>
       
       
        {/* begin::Toolbar */}
        {/*<div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_2_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_2_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_2_week_btn'
          >
            Week
          </a>
        </div>*/}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidgetToken}

function getChartOptions(height: number,xaxis:any[],yaxis:any[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-warning')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  console.log("axis:::::::::::::",xaxis,yaxis)

  return {
    series: [
      {
        name: 'Tokens',
        data: yaxis,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date().toDateString()
              }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        },
        
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 5,
        distributed:false
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xaxis,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ''
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 1,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
