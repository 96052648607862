/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../IAppModels'
import {useIllustrationsPath} from '../../../../../_metronic/helpers'

const Step3 = () => {
  return (
    <>
      <div data-kt-stepper-element='content'>
        <div className='w-100 text-center'>
          {/* begin::Heading */}
          <h1 className='fw-bold text-dark mb-3'>Congratulations!</h1>
          {/* end::Heading */}

          {/* begin::Description */}
          <div className='text-muted fw-semibold fs-3'>
            Your new project has been created successfully.
          </div>
          {/* end::Description */}

        
        </div>
      </div>
    </>
  )
}

//export {Step5}
export {Step3}
