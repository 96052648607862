import React, { useEffect, useState } from 'react'
import { PriceEditModal } from './priceEditModal'
import { KTSVG } from '../../../_metronic/helpers'
import { KTIcon } from '../../../_metronic/helpers'
import axios from "axios"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
const API_URL = process.env.REACT_APP_API_URL



const initialValues = [{
    planid: 0,
    planname: "",
    amount: "0",
    amounttype: "",
    noofusers: "",
    noofpages: "",
    nooftokens: "",
    noofprojects: "",
    docSize: "",
    tokenSize: ""
}]


const initialValuess = {
    planid: 0,
    planname: "",
    amount: "0",
    amounttype: "month",
    noofusers: "0",
    noofpages: "0",
    nooftokens: "0",
    noofprojects: "0",
    docSize: "0",
    tokenSize: "0"
}
interface Subscription {
    planid: Number,
    planname: String,
    amount: String,
    amounttype: String,
    noofusers: String,
    noofpages: String,
    nooftokens: String,
    noofprojects: String,
    docSize: String,
    tokenSize: String
}





const initialData = [{
    planid: 0,
    planname: "",
    amount: "0",
    amounttype: "month",
    noofusers: "0",
    noofpages: "0",
    nooftokens: "0",
    noofprojects: "0",
    docSize: "0",
    tokenSize: "0"
}]
type SubscriptionData = {
    planid: Number,
    planname: String,
    amount: String,
    amounttype: String,
    noofusers: String,
    noofpages: String,
    nooftokens: String,
    noofprojects: String,
    docSize: String,
    tokenSize: String
}
const subscriptionplanSchema = Yup.object().shape({
    planname: Yup.string().required('Plan name is required'),
    amount: Yup.string().required('Amount is required'),
    amounttype: Yup.string().required('Amount Type is required'),
    noofusers: Yup.string().required('Number of Users is required'),
    noofpages: Yup.string().required('Number of Pages is required'),
    nooftokens: Yup.string().required('Number of tokens is required'),
    noofprojects: Yup.string().required('Number of projects is required'),
    docSize: Yup.string().required('Document size is required'),
    tokenSize: Yup.string().required('Token size is required')

})
const PriceEditPlan: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(initialValues)

    const fetchData = async () => {
        setLoading(true)
        const response = await axios.get(`${API_URL}/subscriptions`)
        console.log('Data:-', response.data)
        setState(response.data)
        setLoading(false)
    }



    const [statesdata, setStatesData] = useState(initialData)

    const fetchDeletedData = async () => {
        setLoading(true)
        const response = await axios.get(`${API_URL}/subscriptions/deletedPlan`)
        console.log('Data:-', response.data)
        setStatesData(response.data)
        setLoading(false)
    }


    useEffect(() => {
        fetchData()
        fetchDeletedData()
    }, [])

    const [states, setStates] = useState<Subscription>(initialValuess)



    const handledataModal = (planid, planname, amount, amounttype, noofpages, noofprojects, nooftokens, noofusers, docSize, tokenSize) => {
        console.log("planid,planname,amount,amounttype,noofpages,noofprojects,nooftokens,noofusers", planid, planname, amount, amounttype, noofpages, noofprojects, nooftokens, noofusers, docSize, tokenSize)
        setStates({
            planid: planid,
            planname: planname,
            amount: amount,
            amounttype: amounttype,
            noofusers: noofusers,
            noofpages: noofpages,
            nooftokens: tokenSize == "Unlimited" ? "" : `${Number(nooftokens / 1000)}`,
            noofprojects: noofprojects,
            docSize: docSize,
            tokenSize: tokenSize
        })
    }
    console.log("Array=============================================>", Array.isArray(statesdata));

    console.log("state:----------================================================>", states)
    const formik = useFormik<Subscription>({
        initialValues: states,
        enableReinitialize: true,
        validationSchema: subscriptionplanSchema,
        onSubmit: async (values) => {
            console.log("Values------------->", values)
            setLoading(true);
            try {
                const response = await axios.post(`${API_URL}/subscriptions/pricingPlan-edit`, {
                    ...values,
                }).then(
                    () => {
                        Swal.fire({
                            text: "New Subscription Plan Added",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Okay",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    }
                )
                console.log(response);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
    })

    const handleDeleteModal = async (planid) => {
        Swal.fire({
            text: "Are you sure , you want to delete the subscription",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            showCloseButton: true,
            customClass: {
                confirmButton: "btn btn-primary"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${API_URL}/subscriptions/delete-pricingPlan/${planid}`).then(() => {
                    Swal.fire("Subscription Plan Deleted", "", "success").then(() => {
                        window.location.reload()
                    });
                })
            }
        })

    }

    console.log("states-------->", states)
    console.log("formik.values===========>", formik.values)

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "30px" }}>
                <button type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_2"
                    style={{borderRadius:"30px",backgroundColor:"#071437"}}

                >
                    Add Subscription
                </button>
                <div className="modal bg-white fade" tabIndex={-1} id="kt_modal_2">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content shadow-none">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Subscription</h5>
                                <div
                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                            <div className="modal-body">
                                <PriceEditModal />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>

                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                    <li className="nav-item">
                        <a
                            className="nav-link fs-4 fw-bold active"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_1"
                            style={{color:"#071437"}}
                        >
                            Active
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link fs-4 fw-bold"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_2"
                            style={{color:"#071437"}}
                        >
                            Archived
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade active show"
                        id="kt_tab_pane_1"
                        role="tabpanel"
                    >
                        <div className="table-responsive" >
                            <table className="table table-striped gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800  border-black-200" style={{backgroundColor:"#658ed9"}}>
                                        <th className="min-w-100px">Plan Name</th>
                                        <th className="min-w-100px">Recurring Amount</th>
                                        <th className="min-w-100px">Amont Type</th>
                                        <th className="min-w-100px">Document Size</th>
                                        <th className="min-w-100px">Users </th>
                                        <th className="min-w-100px">Tokens</th>
                                        <th className="min-w-100px">Projects</th>
                                        <th className="min-w-100px">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {state.map((m, idx) => {

                                        return (
                                            <tr>
                                                <td>{m.planname}</td>
                                                <td>${m.amount}</td>
                                                <td>{m.amounttype}</td>
                                                <td>{m.noofpages}{m.docSize}</td>
                                                <td>{m.noofusers}</td>
                                                <td>{m.nooftokens == "Unlimited" ? "Unlimited" : Number(m.nooftokens) / 1000}{m.tokenSize}</td>
                                                <td>{m.noofprojects}</td>
                                                <td>
                                                    <div className='d-flex justify-content-start flex-shrink-0'>

                                                        <button
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#kt_modal_3"
                                                            onClick={() => handledataModal(m.planid, m.planname, m.amount, m.amounttype, m.noofpages, m.noofprojects, m.nooftokens, m.noofusers, m.docSize, m.tokenSize)}
                                                        >
                                                            <KTIcon iconName='pencil' className='fs-3' />
                                                        </button>
                                                        <button

                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                            onClick={() => handleDeleteModal(m.planid)}
                                                        >
                                                            <KTIcon iconName='trash' className='fs-3' />
                                                        </button>
                                                    </div>
                                                </td>
                                                <div className="modal bg-white fade" tabIndex={-1} id="kt_modal_3" >
                                                    <div className="modal-dialog modal-fullscreen">
                                                        <div className="modal-content shadow-none">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">Add New Subscription</h5>
                                                                <div
                                                                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <KTSVG
                                                                        path="/media/icons/duotune/arrows/arr061.svg"
                                                                        className="svg-icon svg-icon-2x"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="modal-body">
                                                                {/*<PriceEditTableModal planname={m.planname} planid={m.planid} amount={m.amount} amounttype={m.amounttype} noofpages={m.noofpages} noofprojects={m.noofprojects} nooftokens={m.nooftokens} noofusers ={m.noofusers} />*/}
                                                                <div>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <div className="col-lg-5 mb-5 mb-lg-0"  >
                                                                                    <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px" }}>
                                                                                        <div className='card-body border-top p-9' style={{ textAlign: "left" }}>
                                                                                            <div className='row mb-6'>
                                                                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Plan name : </label>

                                                                                                <div className='col-lg-8'>

                                                                                                    <input
                                                                                                        type='text'
                                                                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                                                                        placeholder='Plan name'
                                                                                                        {...formik.getFieldProps('planname')}

                                                                                                    />
                                                                                                    {formik.touched.planname && formik.errors.planname && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'></div>
                                                                                                        </div>
                                                                                                    )}

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className='row mb-6'>
                                                                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Amount for plan : </label>

                                                                                                <div className='col-lg-8 fv-row'>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        className='form-control form-control-lg form-control-solid'
                                                                                                        placeholder='Enter Amount'
                                                                                                        {...formik.getFieldProps('amount')}

                                                                                                    />
                                                                                                    {formik.touched.amount && formik.errors.amount && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'></div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>


                                                                                            <div className='row mb-6'>
                                                                                                <div style={{ display: "flex" }}>
                                                                                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                                                                        <span className='required'>Amount Type :</span>
                                                                                                    </label>
                                                                                                    <div className='form-check form-check-custom form-check-solid'>
                                                                                                        <input
                                                                                                            className='form-check-input me-3'
                                                                                                            {...formik.getFieldProps('amounttype')}
                                                                                                            name='amounttype'
                                                                                                            type='radio'
                                                                                                            value='month'
                                                                                                            id='kt_modal_update_role_option_0'
                                                                                                            checked={formik.values.amounttype === 'month'}
                                                                                                            disabled={formik.isSubmitting}
                                                                                                        />
                                                                                                        <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                                                                                                            <div className='fw-bolder text-gray-800'>Per Month</div>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='form-check form-check-custom form-check-solid' style={{ paddingLeft: "20px" }}>
                                                                                                        <input
                                                                                                            className='form-check-input me-3'
                                                                                                            {...formik.getFieldProps('amounttype')}
                                                                                                            name='amounttype'
                                                                                                            type='radio'
                                                                                                            value='year'
                                                                                                            id='kt_modal_update_role_option_0'
                                                                                                            checked={formik.values.amounttype === 'year'}
                                                                                                            disabled={formik.isSubmitting}
                                                                                                        />

                                                                                                        <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                                                                                                            <div className='fw-bolder text-gray-800'>Per Year</div>
                                                                                                        </label>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className='row mb-6'>
                                                                                                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                                                                                    <span className='required'>Enter the number of Users :</span>
                                                                                                </label>

                                                                                                <div className='col-lg-6 fv-row'>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        className='form-control form-control-lg form-control-solid'
                                                                                                        placeholder='Number of Users'
                                                                                                        {...formik.getFieldProps('noofusers')}

                                                                                                    />
                                                                                                    {formik.touched.noofusers && formik.errors.noofusers && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'>{ }</div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            {/*{tenantId == 0 &&  */}
                                                                                            <div className='row mb-6'>
                                                                                                <label className='col-lg-5 col-form-label fw-bold fs-6'>
                                                                                                    <span className='required'>Size of the Document :</span>
                                                                                                </label>

                                                                                                <div className='col-lg-3 fv-row'>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        className='form-control form-control-lg form-control-solid'
                                                                                                        placeholder='Number of Pages'
                                                                                                        {...formik.getFieldProps('noofpages')}

                                                                                                    />
                                                                                                    {formik.touched.noofpages && formik.errors.noofpages && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'>{ }</div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className='col-lg-4 fv-row' >
                                                                                                    <select
                                                                                                        className='form-select form-select-solid form-select-lg'
                                                                                                        {...formik.getFieldProps('docSize')}
                                                                                                    >
                                                                                                        <option value=''>Select ...</option>
                                                                                                        <option value='kb'>Kb</option>
                                                                                                        <option value='mb'>Mb</option>
                                                                                                        <option value='unlimited'>Unlimited</option>
                                                                                                    </select>
                                                                                                    {formik.touched.docSize && formik.errors.docSize && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'></div>
                                                                                                        </div>
                                                                                                    )}

                                                                                                </div>
                                                                                            </div>
                                                                                            {/*}*/}
                                                                                            <div className='row mb-6'>
                                                                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                                                                    <span className='required'>Number of Tokens :</span>
                                                                                                </label>

                                                                                                <div className='col-lg-4 fv-row'>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        className='form-control form-control-lg form-control-solid'
                                                                                                        placeholder='Number of Tokens'
                                                                                                        {...formik.getFieldProps('nooftokens')}

                                                                                                    />
                                                                                                    {formik.touched.nooftokens && formik.errors.nooftokens && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'></div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className='col-lg-4 fv-row' >
                                                                                                    <select
                                                                                                        className='form-select form-select-solid form-select-lg'
                                                                                                        {...formik.getFieldProps('tokenSize')}
                                                                                                    >
                                                                                                        <option value=''>Select ...</option>
                                                                                                        <option value='k'>K</option>
                                                                                                        <option value='unlimited'>Unlimited</option>
                                                                                                    </select>
                                                                                                    {formik.touched.tokenSize && formik.errors.tokenSize && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'></div>
                                                                                                        </div>
                                                                                                    )}

                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='row mb-6'>
                                                                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                                                                    <span className='required'>Number of Projects :</span>
                                                                                                </label>

                                                                                                <div className='col-lg-8 fv-row'>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        className='form-control form-control-lg form-control-solid'
                                                                                                        placeholder='Number of Projects'
                                                                                                        {...formik.getFieldProps('noofprojects')}

                                                                                                    />
                                                                                                    {formik.touched.noofprojects && formik.errors.noofprojects && (
                                                                                                        <div className='fv-plugins-message-container'>
                                                                                                            <div className='fv-help-block'>{ }</div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>






                                                                                        </div>



                                                                                        <button type='submit' className="btn btn-primary btn-block p-2 shadow" style={{ width: "80%", borderRadius: "20px" }}>Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-2 mb-5 mb-lg-0" >
                                                                                    <div className="bg-white p-5 rounded-lg shadow" style={{ borderRadius: "20px", width: "300px", color: "grey" }}>
                                                                                        <h1 className="h6 text-uppercase font-weight-bold mb-4" style={{ fontSize: "20px", paddingTop: "10px" }}>{formik.values.planname ? formik.values.planname : "Plan Name"} </h1>

                                                                                        <h2 className="h1 font-weight-bold" style={{ fontSize: "40px", paddingBottom: "15px", paddingTop: "15px" }}>${formik.values.amount}/<span style={{ fontSize: "20px" }}>{formik.values.amounttype}</span></h2>
                                                                                        <div className="custom-separator my-4 mx-auto bg-primary"></div>
                                                                                        <ul className="list-unstyled my-5 text-small text-left" style={{ fontSize: "15px" }}>
                                                                                            <li className="mb-3">
                                                                                                <i className="fa fa-check mr-2 text-primary"></i> {formik.values.noofusers} Users</li>
                                                                                            <li className="mb-3">
                                                                                                <i className="fa fa-check mr-2 text-primary"></i> {formik.values.noofpages} Pages</li>
                                                                                            <li className="mb-3">
                                                                                                <i className="fa fa-check mr-2 text-primary"></i> {formik.values.nooftokens}k tokens / month</li>
                                                                                            <li className="mb-3">
                                                                                                <i className="fa fa-check mr-2 text-primary"></i> {formik.values.noofprojects} Projectjs</li>
                                                                                        </ul>
                                                                                        <button className="btn btn-primary btn-block p-2 shadow rounded-pill" disabled={true} >Subscribe</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-light"
                                                                    data-bs-dismiss="modal"
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </tr>

                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                        <div className="table-responsive" >
                            <table className="table table-striped gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"  style={{backgroundColor:"#918b8b"}}>
                                        <th className="min-w-100px">Plan Name</th>
                                        <th className="min-w-100px">Recurring Amount</th>
                                        <th className="min-w-100px">Amont Type</th>
                                        <th className="min-w-100px">Document Size</th>
                                        <th className="min-w-100px">Users </th>
                                        <th className="min-w-100px">Tokens</th>
                                        <th className="min-w-100px">Projects</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {statesdata.map((data, idx) => {

                                        return (
                                            <tr>
                                                <td>{data.planname}</td>
                                                <td>${data.amount}</td>
                                                <td>{data.amounttype}</td>
                                                <td>{data.noofpages}{data.docSize}</td>
                                                <td>{data.noofusers}</td>
                                                <td>{data.nooftokens == "Unlimited" ? "Unlimited" : Number(data.nooftokens) / 1000}{data.tokenSize}</td>
                                                <td>{data.noofprojects}</td>



                                            </tr>

                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    )
}

export { PriceEditPlan }